import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

const GoogleOAuthConfig = ({ children }: any) => {
  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      {children}
    </GoogleOAuthProvider>
  );
};

export default GoogleOAuthConfig;
