import * as React from "react";
import {
  PaletteMode,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { FormattedMessage } from "react-intl";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

import ToggleColorMode from "../ToggleColorMode/ToggleColorMode";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/auth/authOperations";

interface HomeHeaderProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function HomeHeader({ mode, toggleColorMode }: HomeHeaderProps) {
  const dispatch = useDispatch<AppDispatch>();
  const email = useSelector((state: RootState) => state.auth.user.email);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    dispatch(logOut());
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
        mt: { xs: 1, sm: 2 },
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
            borderRadius: "999px",
            bgcolor:
              theme.palette.mode === "light"
                ? "rgba(255, 255, 255, 0.4)"
                : "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(24px)",
            maxHeight: 40,
            border: "1px solid",
            borderColor: "divider",
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              ml: 2,
              px: 0,
            }}
          >
            <Link to={"/home"} style={{ textDecoration: "none" }}>
              <Typography color="primary">
                <FormattedMessage id="homeHeader.home" />
              </Typography>
            </Link>
          </Box>
          {isMobile ? (
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={toggleMenu}
              sx={{
                color: (theme) => theme.palette.primary.main,
                bgcolor: (theme) => theme.palette.background.paper,
                "&:hover": {
                  bgcolor: (theme) => theme.palette.action.hover,
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {email && (
                <Link to={"/profile"} style={{ textDecoration: "none" }}>
                  <Typography color={"primary"} sx={{ mr: 1 }}>
                    {email}
                  </Typography>
                </Link>
              )}
              <LanguageSelector />
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              <Button
                color="primary"
                variant="outlined"
                onClick={handleLogout}
                fullWidth
              >
                <FormattedMessage id="homeHeader.logout" />
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
      <Drawer anchor="right" open={isMenuOpen} onClose={toggleMenu}>
        <BurgerMenu
          email={email || ""}
          mode={mode}
          toggleColorMode={toggleColorMode}
          onClose={toggleMenu}
        />
      </Drawer>
    </AppBar>
  );
}

export default HomeHeader;
