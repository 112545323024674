import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Instructor } from "../../../../redux/types/types";
import CustomTextField from "../../../CustomTextField/CustomTextField";
import LatinTextField from "../../../../helpers/latinTextField";
import EmailTextField from "../../../../helpers/EmailTextField";

interface CreateInstructorDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (instructorData: Omit<Instructor, "id" | "role">) => void;
}

export default function CreateInstructorDialog({
  open,
  onClose,
  onSave,
}: CreateInstructorDialogProps) {
  const intl = useIntl();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage({ id: "admin.dashboard.invalidEmail" }))
      .required(intl.formatMessage({ id: "admin.dashboard.emailRequired" })),
    firstname: Yup.string()
      .required(intl.formatMessage({ id: "admin.dashboard.firstnameRequired" }))
      .min(2, intl.formatMessage({ id: "admin.dashboard.firstnameMinLength" }))
      .max(
        50,
        intl.formatMessage({ id: "admin.dashboard.firstnameMaxLength" })
      ),
    lastname: Yup.string()
      .required(intl.formatMessage({ id: "admin.dashboard.lastnameRequired" }))
      .min(2, intl.formatMessage({ id: "admin.dashboard.lastnameMinLength" }))
      .max(50, intl.formatMessage({ id: "admin.dashboard.lastnameMaxLength" })),
    phone: Yup.string()
      .required(intl.formatMessage({ id: "admin.dashboard.phoneRequired" }))
      .test(
        "is-valid-phone-number",
        intl.formatMessage({ id: "signUp.invalidPhoneNumber" }),
        (value) => isValidPhoneNumber(value)
      ),
  });

  const handleSubmit = (values: Omit<Instructor, "id" | "role">) => {
    onSave(values);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      onBackdropClick={(event) => event.stopPropagation()}
    >
      <DialogTitle id="form-dialog-title">
        <FormattedMessage id="admin.dashboard.createInstructor" />
      </DialogTitle>
      <Formik
        initialValues={{
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form noValidate>
            <DialogContent>
              <Field
                as={EmailTextField}
                margin="dense"
                required
                fullWidth
                id="email"
                name="email"
                label={intl.formatMessage({ id: "admin.dashboard.email" })}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <Field
                as={LatinTextField}
                margin="dense"
                required
                fullWidth
                id="firstname"
                name="firstname"
                label={intl.formatMessage({
                  id: "admin.dashboard.firstname.input",
                })}
                error={touched.firstname && !!errors.firstname}
                helperText={touched.firstname && errors.firstname}
              />
              <Field
                as={LatinTextField}
                margin="dense"
                required
                fullWidth
                id="lastname"
                name="lastname"
                label={intl.formatMessage({
                  id: "admin.dashboard.lastname.input",
                })}
                error={touched.lastname && !!errors.lastname}
                helperText={touched.lastname && errors.lastname}
              />
              <div style={{ margin: "16px 0" }}>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={values.phone}
                  onChange={(value) => setFieldValue("phone", value)}
                  inputComponent={CustomTextField as any}
                />
                {touched.phone && errors.phone && (
                  <Typography color="error" variant="caption">
                    {errors.phone}
                  </Typography>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>
                <FormattedMessage id="admin.dashboard.cancel" />
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                <FormattedMessage id="admin.dashboard.create" />
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
