import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchLocations,
  deleteLocation,
  updateLocation,
  createLocation,
} from "../../../redux/admin/adminOperations";
import { Location } from "../../../redux/types/types";

type Order = "asc" | "desc";

export const useLocationManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { locations, totalLocations, loading, error } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState<number | null>(null);
  const [locationToEdit, setLocationToEdit] = useState<Location | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof Location>("id");
  const [order, setOrder] = useState<Order>("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchLocationData = useCallback(() => {
    dispatch(
      fetchLocations({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortOrder: order,
        search: searchTerm,
      })
    );
  }, [dispatch, page, rowsPerPage, orderBy, order, searchTerm]);

  const handleDeleteLocation = useCallback((locationId: number) => {
    setLocationToDelete(locationId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteLocation = useCallback(() => {
    if (locationToDelete) {
      dispatch(deleteLocation(locationToDelete))
        .unwrap()
        .then(() => {
          fetchLocationData();
          setDeleteDialogOpen(false);
          setLocationToDelete(null);
        })
        .catch((error) => {
          console.error("Failed to delete location:", error);
        });
    }
  }, [locationToDelete, dispatch, fetchLocationData]);

  const handleEditLocation = useCallback((location: Location) => {
    setLocationToEdit(location);
    setEditDialogOpen(true);
  }, []);

  const handleUpdateLocation = useCallback(() => {
    if (locationToEdit && locationToEdit.id) {
      dispatch(
        updateLocation({
          locationId: locationToEdit.id,
          locationData: locationToEdit,
        })
      )
        .unwrap()
        .then(() => {
          fetchLocationData();
          setEditDialogOpen(false);
          setLocationToEdit(null);
        })
        .catch((error) => {
          console.error("Failed to update location:", error);
        });
    }
  }, [locationToEdit, dispatch, fetchLocationData]);

  const handleCreateLocation = useCallback(
    (locationData: Omit<Location, "id">) => {
      dispatch(createLocation(locationData))
        .unwrap()
        .then(() => {
          fetchLocationData();
          setCreateDialogOpen(false);
        })
        .catch((error) => {
          console.error("Failed to create location:", error);
        });
    },
    [dispatch, fetchLocationData]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleRequestSort = useCallback(
    (property: keyof Location) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    },
    []
  );

  return {
    locations,
    totalLocations,
    loading,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    locationToDelete,
    locationToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    fetchLocationData,
    handleDeleteLocation,
    confirmDeleteLocation,
    handleEditLocation,
    handleUpdateLocation,
    handleCreateLocation,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setLocationToEdit,
  };
};
