import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { useMediaQuery, Box } from "@mui/material";
import { LanguageProvider } from "../LanguageProvider/LanguageProvider";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const defaultTheme = createTheme();

export default function SignInSide() {
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={defaultTheme}>
      <LanguageProvider>
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          {!isMobile && (
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: "url(https://unsplash.it/1920/1080?random)",
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box sx={{ position: "absolute", top: 16, right: 16 }}>
              <LanguageSelector />
            </Box>
            <Outlet />
          </Grid>
        </Grid>
      </LanguageProvider>
    </ThemeProvider>
  );
}
