import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const AdminRoute: React.FC = () => {
  const { isAuthenticated, isAdmin } = useSelector(
    (state: RootState) => state.auth
  );

  if (!isAuthenticated || !isAdmin) {
    return <Navigate to="/admin/signin" replace />;
  }

  if (!isAdmin) {
    return <Navigate to="/profile" replace />;
  }

  return <Outlet />;
};

export default AdminRoute;
