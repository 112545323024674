import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  IconButton,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage, useIntl, FormattedDate } from "react-intl";
import { Attendee } from "../../../redux/types/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { enrollWaitingAttendee } from "../../../redux/enrollment/enrollmentOperations";
import { getProfile } from "../../../redux/auth/authOperations";

interface WaitingListModalProps {
  open: boolean;
  onClose: () => void;
  waitingAttendees: Attendee[];
}

export default function WaitingListModal({
  open,
  onClose,
  waitingAttendees,
}: WaitingListModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();

  const [attendees, setAttendees] = useState<Attendee[]>([]);

  const handleEnrollClick = async (
    waitingAttendeeId: number,
    attendeeId: number
  ) => {
    await dispatch(enrollWaitingAttendee(waitingAttendeeId));
    await setAttendees((prev) => prev.filter((a) => a.id !== attendeeId));
    await dispatch(getProfile());
  };

  useEffect(() => {
    setAttendees(waitingAttendees);
  }, [waitingAttendees, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormattedMessage
          id="myClasses.waitingList"
          defaultMessage="Waiting List"
        />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List>
          {attendees.map((attendee) => (
            <ListItem key={attendee.id} divider alignItems="flex-start">
              <Container style={{ paddingLeft: 0 }}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span">
                      {intl.formatMessage({
                        id: "waitingList.user",
                        defaultMessage: "User",
                      })}
                      {": "}
                      {`${attendee.user?.firstname} ${attendee.user?.lastname}`}
                    </Typography>
                  }
                />
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span">
                      {intl.formatMessage({
                        id: "waitingList.attendee",
                        defaultMessage: "Attendee",
                      })}
                      {": "}
                      {`${attendee.firstName} ${attendee.lastName}`}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="body2"
                        component="span"
                        display="block"
                      >
                        {intl.formatMessage({
                          id: "waitingList.enrollmentDate",
                          defaultMessage: "Enrollment date",
                        })}
                        {": "}
                        {attendee.createdAt && (
                          <FormattedDate
                            value={attendee.createdAt}
                            year="numeric"
                            month="long"
                            day="numeric"
                          />
                        )}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </Container>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (attendee.waitingAttendeeId)
                    handleEnrollClick(attendee.waitingAttendeeId, attendee.id);
                }}
                disabled={!attendee.canEnroll}
                sx={{ mt: 1 }}
              >
                <FormattedMessage
                  id="waitingList.enroll"
                  defaultMessage="Enroll"
                />
              </Button>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
