import React from "react";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useLanguage, Language } from "../LanguageProvider/LanguageProvider";

const LanguageSelector: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  const handleChange = (event: SelectChangeEvent<Language>) => {
    setLanguage(event.target.value as Language);
  };

  return (
    <Select
      value={language}
      onChange={handleChange}
      size="small"
      sx={{
        color: "primary.main",
        "& .MuiSelect-icon": { color: "primary.main" },
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        bgcolor: "background.paper",
        borderRadius: 1,
      }}
    >
      <MenuItem value="en">EN</MenuItem>
      <MenuItem value="fr">FR</MenuItem>
      <MenuItem value="uk">UK</MenuItem>
    </Select>
  );
};

export default LanguageSelector;
