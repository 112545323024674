import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TablePagination,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { Type } from "../../../../redux/types/types";

interface TypesListProps {
  types: Type[];
  totalTypes: number;
  page: number;
  rowsPerPage: number;
  orderBy: keyof Type | any;
  order: "asc" | "desc";
  onRequestSort: (property: keyof Type) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditType: (type: Type) => void;
  onDeleteType: (typeId: number) => void;
}

const languages = ["en", "uk", "fr"] as const;
type Language = (typeof languages)[number];

const LocalizedContent: React.FC<{ content: Record<Language, string> }> = ({
  content,
}) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    {languages.map(
      (lang) =>
        content[lang] && (
          <Box
            key={lang}
            sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
          >
            <Chip
              label={lang.toUpperCase()}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ width: 40, mr: 1, flexShrink: 0 }}
            />
            <Typography variant="body2" component="span">
              {content[lang]}
            </Typography>
          </Box>
        )
    )}
  </Box>
);

export default function TypesList({
  types,
  totalTypes,
  page,
  rowsPerPage,
  orderBy,
  order,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  onEditType,
  onDeleteType,
}: TypesListProps) {
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="type table">
          <TableHead>
            <TableRow>
              {["id", "name"].map((column) => (
                <TableCell
                  key={column}
                  sx={{ pl: column !== "id" ? "56px" : undefined }}
                >
                  <TableSortLabel
                    active={orderBy === column}
                    direction={orderBy === column ? order : "asc"}
                    onClick={() => onRequestSort(column as keyof Type)}
                  >
                    <FormattedMessage id={`admin.dashboard.${column}`} />
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell>
                <FormattedMessage id="admin.dashboard.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {types.map((type: Type) => (
              <TableRow key={type.id}>
                <TableCell>{type.id}</TableCell>
                <TableCell sx={{ pl: 1 }}>
                  <LocalizedContent content={type.name} />
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => onEditType(type)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => onDeleteType(type.id)}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalTypes}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={<FormattedMessage id="admin.dashboard.rowsPerPage" />}
      />
    </>
  );
}
