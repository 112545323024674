import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

interface LanguageFields {
  en: string;
  uk: string;
  fr: string;
}

interface Location {
  id: number;
  name: LanguageFields;
  address: LanguageFields;
}

interface EditLocationDialogProps {
  open: boolean;
  locationToEdit: Location | null;
  onClose: () => void;
  onSave: () => void;
  onLocationChange: (updatedLocation: Location) => void;
}

export default function EditLocationDialog({
  open,
  locationToEdit,
  onClose,
  onSave,
  onLocationChange,
}: EditLocationDialogProps) {
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState<"en" | "uk" | "fr">("en");

  const handleChange =
    (field: "name" | "address", language: keyof LanguageFields) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (locationToEdit) {
        const updatedLocation: Location = {
          ...locationToEdit,
          [field]: {
            ...(locationToEdit[field] as LanguageFields),
            [language]: e.target.value,
          },
        };
        onLocationChange(updatedLocation);
      }
    };

  if (!locationToEdit) {
    return null;
  }

  const defaultLanguageFields: LanguageFields = { en: "", uk: "", fr: "" };
  const name = locationToEdit.name || defaultLanguageFields;
  const address = locationToEdit.address || defaultLanguageFields;

  const isEnglishEmpty = name.en.trim() === "" || address.en.trim() === "";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <FormattedMessage id="admin.dashboard.editLocation" />
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={currentTab}
          onChange={(_, newValue: "en" | "uk" | "fr") =>
            setCurrentTab(newValue)
          }
          aria-label="language tabs"
        >
          <Tab
            label={intl.formatMessage({ id: "common.english" })}
            value="en"
          />
          <Tab
            label={intl.formatMessage({ id: "common.ukrainian" })}
            value="uk"
            disabled={isEnglishEmpty}
          />
          <Tab
            label={intl.formatMessage({ id: "common.french" })}
            value="fr"
            disabled={isEnglishEmpty}
          />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {currentTab === "en" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="name-en"
                label={intl.formatMessage({ id: "admin.dashboard.nameEn" })}
                type="text"
                fullWidth
                value={name.en}
                onChange={handleChange("name", "en")}
              />
              <TextField
                margin="dense"
                id="address-en"
                label={intl.formatMessage({ id: "admin.dashboard.addressEn" })}
                type="text"
                fullWidth
                value={address.en}
                onChange={handleChange("address", "en")}
              />
            </>
          )}
          {currentTab === "uk" && (
            <>
              <TextField
                margin="dense"
                id="name-uk"
                label={intl.formatMessage({ id: "admin.dashboard.nameUk" })}
                type="text"
                fullWidth
                value={name.uk}
                onChange={handleChange("name", "uk")}
              />
              <TextField
                margin="dense"
                id="address-uk"
                label={intl.formatMessage({ id: "admin.dashboard.addressUk" })}
                type="text"
                fullWidth
                value={address.uk}
                onChange={handleChange("address", "uk")}
              />
            </>
          )}
          {currentTab === "fr" && (
            <>
              <TextField
                margin="dense"
                id="name-fr"
                label={intl.formatMessage({ id: "admin.dashboard.nameFr" })}
                type="text"
                fullWidth
                value={name.fr}
                onChange={handleChange("name", "fr")}
              />
              <TextField
                margin="dense"
                id="address-fr"
                label={intl.formatMessage({ id: "admin.dashboard.addressFr" })}
                type="text"
                fullWidth
                value={address.fr}
                onChange={handleChange("address", "fr")}
              />
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        <Button onClick={onSave} color="primary">
          <FormattedMessage id="admin.dashboard.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
