import api from "./axiosInstance";

export const fetchUsersApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) =>
  api.get("/admin/users", {
    params: params,
  });

export const fetchInstructorsApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) =>
  api.get("/admin/instructors", {
    params: params,
  });

export const createUserApi = (userData: any) =>
  api.post("/admin/users", userData);

export const createInstructorApi = (instructorData: any) =>
  api.post("/admin/instructors", instructorData);

export const deleteUserApi = (userId: number) =>
  api.delete(`/admin/users/${userId}`);

export const deleteInstructorApi = (instructorId: number) =>
  api.delete(`/admin/instructors/${instructorId}`);

export const getUserApi = (userId: number) => api.get(`/admin/users/${userId}`);

export const getInstructorApi = (instructorId: number) =>
  api.get(`/admin/instructors/${instructorId}`);

export const updateUserApi = (userId: number, userData: any) =>
  api.patch(`/admin/users/${userId}`, userData);

export const updateInstructorApi = (
  instructorId: number,
  instructorData: any
) => api.patch(`/admin/instructors/${instructorId}`, instructorData);

export const resetUserPasswordApi = (userId: number) =>
  api.post(`/admin/users/${userId}/reset-password`);

export const resetInstructorPasswordApi = (instructorId: number) =>
  api.post(`/admin/instructors/${instructorId}/reset-password`);

export const uploadClassImagesApi = (classId: number, formData: FormData) =>
  api.post(`/upload/images/${classId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteClassImageApi = (classId: number, imageUrl: string) =>
  api.delete(`/upload/images/${classId}`, {
    params: { imageUrl },
  });

export const setClassInProgressOfCancellationApi = (
  classId: number,
  cancelDate: string
) => api.patch(`/admin/classes/${classId}/cancel`, { cancelDate });

export const reactivateClassApi = (classId: number) =>
  api.patch(`/admin/classes/${classId}/reactivate`);

export const getClassReportApi = (params: {
  instructorId?: number;
  classId?: number;
  fromDate?: string;
  toDate?: string;
  typeId?: number;
  locationId?: number;
}) => api.get("/reports/classes", { params });

export const getInstructorClassHoursReportApi = (params: {
  instructorId?: string;
  classId?: string;
  fromDate?: string;
  toDate?: string;
}) => api.get("/reports/instructor-class-hours", { params });

export const getEnrollmentsReportApi = (params: {
  instructorId?: string;
  classId?: string;
  fromDate?: string;
  toDate?: string;
  typeId?: string;
  locationId?: string;
}) => api.get("/reports/enrollments", { params });
