import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteUserApi,
  fetchUsersApi,
  updateUserApi,
  resetUserPasswordApi,
  fetchInstructorsApi,
  deleteInstructorApi,
  updateInstructorApi,
  resetInstructorPasswordApi,
  createInstructorApi,
  uploadClassImagesApi,
  deleteClassImageApi,
  setClassInProgressOfCancellationApi,
  reactivateClassApi,
  getClassReportApi,
  getInstructorClassHoursReportApi,
  getEnrollmentsReportApi,
} from "../../utils/api/admin";
import {
  Class,
  Location,
  Schedule,
  Type,
  Attendee,
  Instructor,
} from "../types/types";
import {
  createLocationApi,
  deleteLocationApi,
  fetchLocationsApi,
  updateLocationApi,
} from "../../utils/api/locations";
import {
  createTypeApi,
  deleteTypeApi,
  fetchTypesApi,
  updateTypeApi,
} from "../../utils/api/types";
import {
  createClassApi,
  deleteClassApi,
  fetchClassesApi,
  updateClassApi,
} from "../../utils/api/classes";
import {
  bulkCreateSchedulesApi,
  bulkDeleteSchedulesApi,
  bulkUpdateSchedulesApi,
  createScheduleApi,
  deleteScheduleApi,
  fetchSchedulesApi,
  updateScheduleApi,
} from "../../utils/api/schedules";
import {
  createAttendeeApi,
  deleteAttendeeApi,
  fetchAttendeesApi,
  fetchAttendeesForUserApi,
  getAttendeesToScheduleApi,
  updateAttendeeApi,
} from "../../utils/api/attendees";

interface FetchUsersParams {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}

export const fetchUsers = createAsyncThunk<
  { users: any[]; total: number },
  FetchUsersParams
>("admin/fetchUsers", async (params) => {
  const response = await fetchUsersApi(params);
  return response.data;
});

export const deleteUser = createAsyncThunk<number, number>(
  "admin/deleteUser",
  async (userId) => {
    await deleteUserApi(userId);
    return userId;
  }
);

export const updateUser = createAsyncThunk<
  any,
  { userId: number; userData: any }
>("admin/updateUser", async ({ userId, userData }, { rejectWithValue }) => {
  try {
    const response = await updateUserApi(userId, userData);
    return response.data;
  } catch (err: any) {
    if (err.response && err.response.data && err.response.data.message) {
      return rejectWithValue(err.response.data.message);
    }
    return rejectWithValue(err.message || "Failed to update user.");
  }
});

export const resetUserPassword = createAsyncThunk<void, number>(
  "admin/resetUserPassword",
  async (userId) => {
    await resetUserPasswordApi(userId);
  }
);

interface FetchInstructorsParams {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}

export const fetchInstructors = createAsyncThunk<
  { instructors: any[]; total: number },
  FetchInstructorsParams
>("admin/fetchInstructors", async (params) => {
  const response = await fetchInstructorsApi(params);
  return response.data;
});

export const deleteInstructor = createAsyncThunk<number, number>(
  "admin/deleteInstructor",
  async (instructorId) => {
    await deleteInstructorApi(instructorId);
    return instructorId;
  }
);

export const updateInstructor = createAsyncThunk<
  any,
  { instructorId: number; instructorData: any }
>(
  "admin/updateInstructor",
  async ({ instructorId, instructorData }, { rejectWithValue }) => {
    try {
      const response = await updateInstructorApi(instructorId, instructorData);
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      }
      return rejectWithValue(err.message || "Failed to update instructor.");
    }
  }
);

export const resetInstructorPassword = createAsyncThunk<void, number>(
  "admin/resetInstructorPassword",
  async (instructorId) => {
    await resetInstructorPasswordApi(instructorId);
  }
);

export const createInstructor = createAsyncThunk<
  any,
  Omit<Instructor, "id" | "role">
>("admin/createInstructor", async (instructorData) => {
  const response = await createInstructorApi({
    ...instructorData,
    isInstructor: true,
  });
  return response.data;
});

interface FetchLocationsParams {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}

export const fetchLocations = createAsyncThunk<
  { locations: Location[]; total: number },
  FetchLocationsParams
>("admin/fetchLocations", async (params) => {
  const response = await fetchLocationsApi(params);
  return response.data;
});

export const deleteLocation = createAsyncThunk<number, number>(
  "admin/deleteLocation",
  async (locationId) => {
    await deleteLocationApi(locationId);
    return locationId;
  }
);

export const updateLocation = createAsyncThunk<
  any,
  { locationId: number; locationData: any }
>("admin/updateLocation", async ({ locationId, locationData }) => {
  const response = await updateLocationApi(locationId, locationData);
  return response.data;
});

export const createLocation = createAsyncThunk<any, Omit<Location, "id">>(
  "admin/createLocation",
  async (locationData) => {
    const response = await createLocationApi(locationData);
    return response.data;
  }
);

interface FetchTypesParams {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}

export const fetchTypes = createAsyncThunk<
  { types: Type[]; total: number },
  FetchTypesParams
>("admin/fetchTypes", async (params) => {
  const response = await fetchTypesApi(params);
  return response.data;
});

export const deleteType = createAsyncThunk<number, number>(
  "admin/deleteType",
  async (typeId) => {
    await deleteTypeApi(typeId);
    return typeId;
  }
);

export const updateType = createAsyncThunk<
  any,
  { typeId: number; typeData: any }
>("admin/updateType", async ({ typeId, typeData }) => {
  const response = await updateTypeApi(typeId, typeData);
  return response.data;
});

export const createType = createAsyncThunk<any, Omit<Type, "id">>(
  "admin/createType",
  async (typeData) => {
    const response = await createTypeApi(typeData);
    return response.data;
  }
);

interface FetchClassesParams {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}

export const fetchClasses = createAsyncThunk<
  { classes: Class[]; total: number },
  FetchClassesParams
>("admin/fetchClasses", async (params) => {
  const response = await fetchClassesApi(params);
  return response.data;
});

export const deleteClass = createAsyncThunk<number, number>(
  "admin/deleteClass",
  async (classId) => {
    await deleteClassApi(classId);
    return classId;
  }
);

export const updateClass = createAsyncThunk<
  any,
  { classId: number; classData: any }
>("admin/updateClass", async ({ classId, classData }) => {
  const response = await updateClassApi(classId, classData);
  return response.data;
});

export const createClass = createAsyncThunk<any, Omit<Class, "id">>(
  "admin/createClass",
  async (classData) => {
    const response = await createClassApi(classData);
    return response.data;
  }
);

interface FetchSchedulesParams {
  classId: number;
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
}

export const fetchSchedules = createAsyncThunk<
  { schedules: Schedule[]; total: number },
  FetchSchedulesParams
>("admin/fetchSchedules", async (params) => {
  const response = await fetchSchedulesApi(params);
  return response.data;
});

export const deleteSchedule = createAsyncThunk<number, number>(
  "admin/deleteSchedule",
  async (scheduleId) => {
    await deleteScheduleApi(scheduleId);
    return scheduleId;
  }
);

export const createSchedule = createAsyncThunk<Schedule, Omit<Schedule, "id">>(
  "admin/createSchedule",
  async (scheduleData) => {
    const response = await createScheduleApi(scheduleData);
    return response.data;
  }
);

export const updateSchedule = createAsyncThunk<
  Schedule,
  { scheduleId: number; scheduleData: Partial<Schedule> }
>("admin/updateSchedule", async ({ scheduleId, scheduleData }) => {
  const response = await updateScheduleApi(scheduleId, scheduleData);
  return response.data;
});

export const bulkCreateSchedules = createAsyncThunk<
  Schedule[],
  Omit<Schedule, "id">[]
>("admin/bulkCreateSchedules", async (schedulesData) => {
  const response = await bulkCreateSchedulesApi(schedulesData);
  return response.data;
});

export const bulkUpdateSchedules = createAsyncThunk<
  Schedule[],
  Partial<Schedule>[]
>("admin/bulkUpdateSchedules", async (schedulesData) => {
  const response = await bulkUpdateSchedulesApi(schedulesData);
  return response.data;
});

export const bulkDeleteSchedules = createAsyncThunk<number[], number[]>(
  "admin/bulkDeleteSchedules",
  async (scheduleIds) => {
    await bulkDeleteSchedulesApi(scheduleIds);
    return scheduleIds;
  }
);

interface FetchAttendeesParams {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}

export const fetchAttendees = createAsyncThunk<
  { attendees: Attendee[]; total: number },
  FetchAttendeesParams
>("admin/fetchAttendees", async (params) => {
  const response = await fetchAttendeesApi(params);
  return response.data;
});

export const fetchAttendeesForUser = createAsyncThunk<Attendee[], number>(
  "admin/fetchAttendeesForUser",
  async (userId) => {
    const response = await fetchAttendeesForUserApi(userId);
    return response.data;
  }
);

export const deleteAttendee = createAsyncThunk<number, number>(
  "admin/deleteAttendee",
  async (attendeeId) => {
    await deleteAttendeeApi(attendeeId);
    return attendeeId;
  }
);

export const updateAttendee = createAsyncThunk<
  any,
  { attendeeId: number; attendeeData: any }
>("admin/updateAttendee", async ({ attendeeId, attendeeData }) => {
  const response = await updateAttendeeApi(attendeeId, attendeeData);
  return response.data;
});

export const createAttendee = createAsyncThunk<any, Omit<Attendee, "id">>(
  "admin/createAttendee",
  async (attendeeData) => {
    const response = await createAttendeeApi(attendeeData);
    return response.data;
  }
);

export const getAttendeesToSchedule = createAsyncThunk<Attendee[], number>(
  "admin/getAttendeesToSchedule",
  async (scheduleId) => {
    const response = await getAttendeesToScheduleApi(scheduleId);
    return response.data;
  }
);

export const uploadClassImages = createAsyncThunk<
  { message: string; imageUrls: string[]; classId: number },
  { classId: number; images: File[] }
>(
  "admin/uploadClassImages",
  async ({ classId, images }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      images.forEach((image) => {
        formData.append("images", image);
      });

      const response = await uploadClassImagesApi(classId, formData);
      return { ...response.data, classId };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteClassImage = createAsyncThunk<
  { message: string; classId: number; imageUrl: string },
  { classId: number; imageUrl: string }
>("admin/deleteClassImage", async ({ classId, imageUrl }) => {
  const response = await deleteClassImageApi(classId, imageUrl);
  return { ...response.data, classId, imageUrl };
});

export const setClassInProgressOfCancellation = createAsyncThunk<
  Class,
  { classId: number; cancelDate: string }
>("admin/setClassInProgressOfCancellation", async ({ classId, cancelDate }) => {
  const response = await setClassInProgressOfCancellationApi(
    classId,
    cancelDate
  );
  return response.data;
});

export const reactivateClass = createAsyncThunk<Class, number>(
  "admin/reactivateClass",
  async (classId) => {
    const response = await reactivateClassApi(classId);
    return response.data;
  }
);

export const getClassReport = createAsyncThunk<
  any,
  {
    instructorId?: number;
    classId?: number;
    fromDate?: string;
    toDate?: string;
    typeId?: number;
    locationId?: number;
  }
>("reports/getClassReport", async (params) => {
  const response = await getClassReportApi(params);
  return response.data;
});

export const getInstructorClassHoursReport = createAsyncThunk<
  any,
  {
    instructorId?: string;
    classId?: string;
    fromDate?: string;
    toDate?: string;
  }
>("reports/getInstructorClassHoursReport", async (params) => {
  const response = await getInstructorClassHoursReportApi(params);
  return response.data;
});

export const getEnrollmentsReport = createAsyncThunk<
  any,
  {
    instructorId?: string;
    classId?: string;
    fromDate?: string;
    toDate?: string;
    typeId?: string;
    locationId?: string;
  }
>("reports/getEnrollmentsReport", async (params) => {
  const response = await getEnrollmentsReportApi(params);
  return response.data;
});
