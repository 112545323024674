import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TablePagination,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  LockReset as LockResetIcon,
} from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { Instructor } from "../../../../redux/types/types";

interface InstructorsListProps {
  instructors: Instructor[];
  totalInstructors: number;
  page: number;
  rowsPerPage: number;
  orderBy: keyof Instructor | any;
  order: "asc" | "desc";
  onRequestSort: (property: keyof Instructor) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditInstructor: (instructor: Instructor) => void;
  onDeleteInstructor: (instructorId: number) => void;
  onResetPassword: (instructorId: number) => void;
}

export default function InstructorsList({
  instructors,
  totalInstructors,
  page,
  rowsPerPage,
  orderBy,
  order,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  onEditInstructor,
  onDeleteInstructor,
  onResetPassword,
}: InstructorsListProps) {
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="instructor table">
          <TableHead>
            <TableRow>
              {["id", "email", "firstname", "lastname", "phone"].map(
                (column) => (
                  <TableCell key={column}>
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? order : "asc"}
                      onClick={() => onRequestSort(column as keyof Instructor)}
                    >
                      <FormattedMessage id={`admin.dashboard.${column}`} />
                    </TableSortLabel>
                  </TableCell>
                )
              )}
              <TableCell>
                <FormattedMessage id="admin.dashboard.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instructors.map((instructor: Instructor) => (
              <TableRow key={instructor.id}>
                <TableCell>{instructor.id}</TableCell>
                <TableCell>{instructor.email}</TableCell>
                <TableCell>{instructor.firstname}</TableCell>
                <TableCell>{instructor.lastname}</TableCell>
                <TableCell>{instructor.phone}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => onEditInstructor(instructor)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      typeof instructor.id === "number" &&
                      onDeleteInstructor(instructor.id)
                    }
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    color="warning"
                    onClick={() =>
                      typeof instructor.id === "number" &&
                      onResetPassword(instructor.id)
                    }
                    size="small"
                  >
                    <LockResetIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalInstructors}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={<FormattedMessage id="admin.dashboard.rowsPerPage" />}
      />
    </>
  );
}
