import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  PaletteMode,
  Button,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { logOut } from "../../../redux/auth/authOperations";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import ToggleColorMode from "../../ToggleColorMode/ToggleColorMode";

interface BurgerMenuProps {
  email: string;
  mode: PaletteMode;
  toggleColorMode: () => void;
  onClose: () => void;
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({
  email,
  mode,
  toggleColorMode,
  onClose,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const handleLogout = async () => {
    dispatch(logOut());
    onClose();
  };

  return (
    <Box
      sx={{
        width: 250,
        p: 2,
        bgcolor:
          theme.palette.mode === "dark"
            ? "rgba(0, 0, 0, 0.8)"
            : "rgba(255, 255, 255, 0.8)",
        height: "100%",
      }}
    >
      <List>
        <ListItem>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <LanguageSelector />
            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
          </Box>
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Link style={{ textDecoration: "none" }} to="/profile">
                <Typography variant="subtitle1" color="primary">
                  {email}
                </Typography>
              </Link>
            }
          />
        </ListItem>
        <ListItem>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleLogout}
            fullWidth
          >
            <FormattedMessage id="homeHeader.logout" />
          </Button>
        </ListItem>
      </List>
    </Box>
  );
};

export default BurgerMenu;
