import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../../redux/store";
import {
  deleteAttendee,
  updateAttendee,
} from "../../redux/enrollment/enrollmentOperations";
import { Attendee } from "../../redux/types/types";
import { getProfile } from "../../redux/auth/authOperations";
import NewAttendeeForm from "./NewAttendeeForm/NewAttendeeForm";
import { LatinTextField } from "../../helpers/latinTextField";
import Notiflix from "notiflix";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomTextField from "../CustomTextField/CustomTextField";

export default function Attendees() {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const attendees = useSelector(
    (state: RootState) => state.auth.user.attendees
  );
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editedAttendee, setEditedAttendee] = useState<Partial<Attendee>>({});
  const [isNewAttendeeFormOpen, setIsNewAttendeeFormOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage({ id: "signUp.firstNameRequired" })
    ),
    lastName: Yup.string().required(
      intl.formatMessage({ id: "signUp.lastNameRequired" })
    ),
    birthdate: Yup.date().required(
      intl.formatMessage({ id: "privateEnroll.birthdateRequired" })
    ),
    sex: Yup.string().required(
      intl.formatMessage({ id: "privateEnroll.sexRequired" })
    ),
    phone: Yup.string()
      .required(intl.formatMessage({ id: "signUp.phoneRequired" }))
      .test(
        "is-valid-phone-number",
        intl.formatMessage({ id: "signUp.invalidPhoneNumber" }),
        (value) => isValidPhoneNumber(value)
      ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "signUp.invalidEmail" }))
      .required(intl.formatMessage({ id: "signUp.emailRequired" })),
    postalCode: Yup.string()
      .matches(
        /^[0-9]{5}(-[0-9]{4})?$/,
        intl.formatMessage({ id: "attendees.invalidPostalCodeFormat" })
      )
      .required(intl.formatMessage({ id: "signUp.postalCodeRequired" })),
  });

  const handleEditAttendee = (attendee: Attendee) => {
    setEditedAttendee({
      ...attendee,
      birthdate: attendee.birthdate ? new Date(attendee.birthdate) : new Date(),
      sex: attendee.sex || "other",
      postalCode: attendee.postalCode || "N/A",
    });
    setIsEditDialogOpen(true);
  };

  const handleDeleteAttendee = (attendeeId: number) => {
    Notiflix.Confirm.show(
      intl.formatMessage({ id: "user.attendee.deleteConfTitle" }),
      intl.formatMessage({ id: "user.attendee.deleteConfMessage" }),
      intl.formatMessage({ id: "common.delete" }),
      intl.formatMessage({ id: "common.cancel" }),
      () => {
        dispatch(deleteAttendee(attendeeId))
          .unwrap()
          .then(() => {
            dispatch(getProfile());
          })
          .catch((error) => {
            console.error("Failed to delete attendee:", error);
          });
      }
    );
  };

  const handleSaveEdit = (values: Partial<Attendee>) => {
    if (editedAttendee.id) {
      dispatch(
        updateAttendee({
          attendeeId: editedAttendee.id,
          attendeeData: {
            ...values,
            birthdate: values.birthdate
              ? new Date(values.birthdate)
              : new Date(),
          },
        })
      )
        .unwrap()
        .then(() => {
          setIsEditDialogOpen(false);
          dispatch(getProfile());
        })
        .catch((error) => {
          console.error("Failed to update attendee:", error);
        });
    }
  };

  const handleChangeBirthdate = (e: any) => {
    setEditedAttendee({ ...editedAttendee, birthdate: e.target.value });
  };

  const renderAttendeeCard = (attendee: Attendee) => (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" component="div" gutterBottom>
          {`${attendee.firstName} ${attendee.lastName}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${intl.formatMessage({ id: "signUp.email" })}: ${attendee.email}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${intl.formatMessage({ id: "signUp.phone" })}: ${attendee.phone}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${intl.formatMessage({
            id: "admin.dashboard.birthdate",
          })}: ${new Date(attendee.birthdate).toLocaleDateString()}`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${intl.formatMessage({ id: "admin.dashboard.sex" })}: ${
            attendee.sex === "female"
              ? intl.formatMessage({
                  id: "privateEnroll.female",
                })
              : attendee.sex === "male"
              ? intl.formatMessage({
                  id: "privateEnroll.male",
                })
              : attendee.sex === "other"
              ? intl.formatMessage({
                  id: "privateEnroll.other",
                })
              : attendee.sex
          }`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${intl.formatMessage({ id: "signUp.postalCode" })}: ${
            attendee.postalCode || "N/A"
          }`}
        </Typography>
      </CardContent>
      <Box sx={{ p: 2, mt: "auto" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleEditAttendee(attendee)}
          sx={{ mr: 1 }}
        >
          <FormattedMessage id="attendees.edit" defaultMessage="Edit" />
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={() => handleDeleteAttendee(attendee.id)}
        >
          <FormattedMessage id="attendees.delete" defaultMessage="Delete" />
        </Button>
      </Box>
    </Card>
  );

  return (
    <Box
      sx={{
        my: 4,
        mx: 2,
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        pt: isMobile ? 5 : 0,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsNewAttendeeFormOpen(true)}
        sx={{ mb: 2 }}
      >
        <FormattedMessage
          id="attendees.createNew"
          defaultMessage="Create New Attendee"
        />
      </Button>

      {attendees.length === 0 ? (
        <Typography>
          <FormattedMessage
            id="attendees.noAttendees"
            defaultMessage="No attendees found."
          />
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {attendees.map((attendee) => (
            <Grid item xs={12} sm={6} key={attendee.id}>
              {renderAttendeeCard(attendee)}
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <FormattedMessage
            id="attendees.editAttendee"
            defaultMessage="Edit Attendee"
          />
        </DialogTitle>
        <Formik
          initialValues={editedAttendee}
          validationSchema={validationSchema}
          onSubmit={handleSaveEdit}
          enableReinitialize
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={LatinTextField}
                      name="firstName"
                      label={intl.formatMessage({
                        id: "signUp.firstName.input",
                      })}
                      error={touched.firstName && errors.firstName}
                      helperText={touched.firstName && errors.firstName}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={LatinTextField}
                      name="lastName"
                      label={intl.formatMessage({
                        id: "signUp.lastName.input",
                      })}
                      error={touched.lastName && errors.lastName}
                      helperText={touched.lastName && errors.lastName}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="birthdate"
                      label={intl.formatMessage({
                        id: "admin.dashboard.birthdate",
                      })}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      error={touched.birthdate && errors.birthdate}
                      helperText={touched.birthdate && errors.birthdate}
                      fullWidth
                      required
                      inputProps={{
                        max: new Date().toISOString().split("T")[0],
                      }}
                      onChange={handleChangeBirthdate}
                      value={
                        editedAttendee.birthdate
                          ? new Date(editedAttendee.birthdate)
                              .toISOString()
                              .split("T")[0]
                          : new Date().toISOString().split("T")[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" required>
                        {intl.formatMessage({ id: "admin.dashboard.sex" })}
                      </FormLabel>
                      <Field as={RadioGroup} name="sex" row>
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "privateEnroll.female",
                          })}
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "privateEnroll.male",
                          })}
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "privateEnroll.other",
                          })}
                        />
                      </Field>
                      <ErrorMessage name="sex">
                        {(msg) => (
                          <Typography
                            color="error"
                            variant="caption"
                            component="div"
                            style={{ marginTop: 4 }}
                          >
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        value={values.phone || ""}
                        onChange={(value) =>
                          setFieldValue("phone", value || "")
                        }
                        inputComponent={CustomTextField as any}
                      />
                      {touched.phone && errors.phone && (
                        <Typography color="error" variant="caption">
                          {errors.phone}
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      name="email"
                      label={intl.formatMessage({ id: "signUp.email" })}
                      type="email"
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="postalCode"
                      label={intl.formatMessage({ id: "signUp.postalCode" })}
                      error={touched.postalCode && errors.postalCode}
                      helperText={touched.postalCode && errors.postalCode}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsEditDialogOpen(false)}>
                  <FormattedMessage
                    id="common.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  <FormattedMessage id="common.save" defaultMessage="Save" />
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <NewAttendeeForm
        open={isNewAttendeeFormOpen}
        onClose={() => setIsNewAttendeeFormOpen(false)}
      />
    </Box>
  );
}
