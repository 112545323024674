import React from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Alert,
  Button,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import ClassesList from "./ClassesList/ClassesList";
import { useClassManagement } from "./useClassManagement";
import DeleteClassDialog from "./DeleteClassDialog/DeleteClassDialog";
import EditClassDialog from "./EditClassDialog/EditClassDialog";
import CreateClassDialog from "./CreateClassDialog/CreateClassDialog";

export const ClassManagement: React.FC = () => {
  const intl = useIntl();
  const {
    classes,
    totalClasses,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    classToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    fetchClassData,
    handleDeleteClass,
    confirmDeleteClass,
    handleEditClass,
    handleUpdateClass,
    handleCreateClass,
    handleSetClassInProgressOfCancellation,
    handleReactivateClass,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setClassToEdit,
  } = useClassManagement();

  React.useEffect(() => {
    fetchClassData();
    localStorage.removeItem("newClassSchedules");
  }, [fetchClassData]);

  const handleOpenEditDialog = (classId: number) => {
    const classToEdit = classes.find((c) => c.id === classId);
    if (classToEdit) {
      setClassToEdit(classToEdit);
      setEditDialogOpen(true);
    }
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setClassToEdit(null);
    fetchClassData();
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" component="div">
                <FormattedMessage id="admin.dashboard.classes" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SearchIcon sx={{ mr: 1 }} />
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={intl.formatMessage({
                    id: "admin.dashboard.searchPlaceholder",
                  })}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setCreateDialogOpen(true)}
          >
            <FormattedMessage id="admin.dashboard.createClass" />
          </Button>
        </Box>
        <ClassesList
          classes={classes}
          totalClasses={totalClasses}
          page={page}
          rowsPerPage={rowsPerPage}
          orderBy={orderBy}
          order={order}
          onRequestSort={handleRequestSort}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onEditClass={handleEditClass}
          onDeleteClass={handleDeleteClass}
          onSetClassInProgressOfCancellation={
            handleSetClassInProgressOfCancellation
          }
          onReactivateClass={handleReactivateClass}
        />
      </Paper>
      <DeleteClassDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={confirmDeleteClass}
      />
      <EditClassDialog
        open={editDialogOpen}
        classToEdit={classToEdit}
        onClose={() => {
          handleCloseEditDialog();
          localStorage.removeItem("newClassSchedules");
        }}
        onSave={handleUpdateClass}
      />
      <CreateClassDialog
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
          fetchClassData();
          localStorage.removeItem("newClassSchedules");
        }}
        onSave={handleCreateClass}
        onOpenEditDialog={handleOpenEditDialog}
      />
    </Box>
  );
};
