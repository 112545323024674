import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { fetchAttendeesForUser } from "../../../../../redux/admin/adminOperations";

interface AttendeesListProps {
  userId: number;
  open: boolean;
  onClose: () => void;
}

const calculateAge = (birthdate: Date) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export default function AttendeesList({
  userId,
  open,
  onClose,
}: AttendeesListProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState(open);
  const intl = useIntl();
  const attendees = useSelector((state: RootState) => state.admin.attendees);
  // eslint-disable-next-line
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editedAttendee, setEditedAttendee] = useState<any>(null);

  useEffect(() => {
    dispatch(fetchAttendeesForUser(userId));
  }, [dispatch, userId]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  // const handleEdit = (attendee: any) => {
  //   setEditingId(attendee.id);
  //   setEditedAttendee({ ...attendee });
  // };

  // const handleSave = async () => {
  //   if (editedAttendee) {
  //     await dispatch(
  //       updateAttendee({
  //         attendeeId: editedAttendee.id,
  //         attendeeData: editedAttendee,
  //       })
  //     );
  //     setEditingId(null);
  //     setEditedAttendee(null);
  //   }
  // };

  // const handleCancel = () => {
  //   setEditingId(null);
  //   setEditedAttendee(null);
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      value = value.replace(/[^A-Za-z\s]/g, "");
    }
    setEditedAttendee({ ...editedAttendee, [e.target.name]: value });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="attendees-list-dialog-title"
    >
      <DialogTitle id="attendees-list-dialog-title">
        <FormattedMessage id="admin.dashboard.attendeesList" />
      </DialogTitle>
      <DialogContent>
        <div style={{ overflowX: "auto", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="admin.dashboard.firstName" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="admin.dashboard.lastName" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="admin.dashboard.birthdate" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="admin.dashboard.age" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="admin.dashboard.sex" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="admin.dashboard.phone" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="admin.dashboard.email" />
                  </TableCell>
                  {/* <TableCell>
                    <FormattedMessage id="admin.dashboard.actions" />
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {attendees?.map((attendee) => (
                  <TableRow key={attendee.id}>
                    <TableCell
                      style={{ minWidth: "150px", verticalAlign: "top" }}
                    >
                      {editingId === attendee.id ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <TextField
                            name="firstName"
                            label="First Name"
                            value={editedAttendee.firstName}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                          />
                        </div>
                      ) : (
                        attendee.firstName
                      )}
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "150px", verticalAlign: "top" }}
                    >
                      {editingId === attendee.id ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <TextField
                            name="lastName"
                            label="Last Name"
                            value={editedAttendee.lastName}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                          />
                        </div>
                      ) : (
                        attendee.lastName
                      )}
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "150px", verticalAlign: "top" }}
                    >
                      {editingId === attendee.id ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <TextField
                            name="birthdate"
                            type="date"
                            value={editedAttendee.birthdate.split("T")[0]}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                          />
                        </div>
                      ) : (
                        intl.formatDate(attendee.birthdate, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      )}
                    </TableCell>
                    <TableCell>{calculateAge(attendee.birthdate)}</TableCell>
                    <TableCell
                      style={{ minWidth: "150px", verticalAlign: "top" }}
                    >
                      {editingId === attendee.id ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <TextField
                            name="sex"
                            label="Sex"
                            value={editedAttendee.sex}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                          />
                        </div>
                      ) : (
                        attendee.sex
                      )}
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "150px", verticalAlign: "top" }}
                    >
                      {editingId === attendee.id ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <TextField
                            name="phone"
                            label="Phone"
                            value={editedAttendee.phone}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                          />
                        </div>
                      ) : (
                        attendee.phone
                      )}
                    </TableCell>
                    <TableCell
                      style={{ minWidth: "150px", verticalAlign: "top" }}
                    >
                      {editingId === attendee.id ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <TextField
                            name="email"
                            label="Email"
                            value={editedAttendee.email}
                            onChange={handleChange}
                            size="small"
                            fullWidth
                          />
                        </div>
                      ) : (
                        attendee.email
                      )}
                    </TableCell>
                    {/* <TableCell>
                      {editingId === attendee.id ? (
                        <>
                          <IconButton onClick={handleSave} size="small">
                            <Save />
                          </IconButton>
                          <IconButton onClick={handleCancel} size="small">
                            <Cancel />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          onClick={() => handleEdit(attendee)}
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      )}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="admin.dashboard.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
