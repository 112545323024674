import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Alert } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { confirmEmailChangeApi } from "../../utils/api/auth";

export default function ConfirmEmailChange() {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const token = searchParams.get("token");

    const confirmEmailChange = async () => {
      try {
        if (!token) {
          throw new Error(
            intl.formatMessage({ id: "confirmEmailChange.noToken" })
          );
        }
        const response = await confirmEmailChangeApi(token);
        setMessage(
          response.message ||
            intl.formatMessage({ id: "confirmEmailChange.success" })
        );
      } catch (error: any) {
        setError(true);
        setMessage(
          error.response?.data?.message ||
            error.message ||
            intl.formatMessage({ id: "confirmEmailChange.error" })
        );
      } finally {
        setIsLoading(false);
      }
    };

    confirmEmailChange();

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      navigate("/auth/signin");
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimeout);
    };
  }, [searchParams, navigate, intl]);

  const handleManualRedirect = () => {
    navigate("/auth/signin");
  };

  return (
    <Box
      sx={{
        my: isMobile ? 4 : 12,
        mx: isMobile ? 2 : 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography component="h1" variant="h5">
        <FormattedMessage
          id={
            error ? "confirmEmailChange.errorTitle" : "confirmEmailChange.title"
          }
        />
      </Typography>
      {isLoading ? (
        <CircularProgress sx={{ my: 2 }} />
      ) : (
        <>
          <Alert severity={error ? "error" : "success"} sx={{ my: 2 }}>
            {message}
          </Alert>
          <Typography component="p" variant="body2" sx={{ my: 2 }}>
            <FormattedMessage
              id="confirmEmailChange.redirectMessage"
              values={{ countdown }}
            />
          </Typography>
          <Button variant="contained" onClick={handleManualRedirect}>
            <FormattedMessage id="confirmEmailChange.goToSignIn" />
          </Button>
        </>
      )}
    </Box>
  );
}
