import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  enrollAttendeeApi,
  fetchUsersApi,
  fetchAttendeesApi,
  fetchSchedulesApi,
} from "../../utils/api/instructor";
import { User, Attendee, Schedule } from "../types/types";

export const enrollAttendee = createAsyncThunk(
  "instructor/enrollAttendee",
  async (
    data: Parameters<typeof enrollAttendeeApi>[0],
    { rejectWithValue }
  ) => {
    try {
      const response = await enrollAttendeeApi(data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUsers = createAsyncThunk<User[], string | undefined>(
  "instructor/fetchUsers",
  async (search) => {
    const response = await fetchUsersApi(search);
    return response.data;
  }
);

export const fetchAttendees = createAsyncThunk<
  Attendee[],
  { userId: number; search?: string }
>("instructor/fetchAttendees", async ({ userId, search }) => {
  const response = await fetchAttendeesApi(userId, search);
  return response.data;
});

export const fetchSchedules = createAsyncThunk<Schedule[], number>(
  "instructor/fetchSchedules",
  async (classId) => {
    const response = await fetchSchedulesApi(classId);
    return response.data;
  }
);
