import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

interface DeleteClassDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function DeleteClassDialog({
  open,
  onClose,
  onConfirm,
}: DeleteClassDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage id="admin.dashboard.deleteClassConfirmTitle" />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage id="admin.dashboard.deleteClassConfirmMessage" />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          <FormattedMessage id="admin.dashboard.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
