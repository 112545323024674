import { configureStore, combineReducers } from "@reduxjs/toolkit";

import auth from "./auth/authSlice";
import admin from "./admin/adminSlice";
import enrollment from "./enrollment/enrollmentSlice";

const rootReducer = combineReducers({
  auth: auth,
  admin: admin,
  enrollment: enrollment,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
