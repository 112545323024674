import React from "react";
import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

interface EmailTextFieldProps {
  name: string;
  label: string;
  [key: string]: any;
}

const EmailTextField: React.FC<EmailTextFieldProps> = ({
  name,
  label,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.trim().toLowerCase();
    setFieldValue(name, trimmedValue);
    field.onBlur(e);
  };

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      onBlur={handleBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export default EmailTextField;
