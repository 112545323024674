import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { logOut } from "../../redux/auth/authOperations";
import {
  createAttendee,
  fetchClassPublic,
} from "../../redux/enrollment/enrollmentOperations";
import { setFrom } from "../../redux/auth/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Language,
  useLanguage,
} from "../../components/LanguageProvider/LanguageProvider";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import { Attendee, Schedule } from "../../redux/types/types";
import EnrollmentHandler from "./EnrollmentHandler/EnrollmentHandler";
import LatinTextField from "../../helpers/latinTextField";
import EmailTextField from "../../helpers/EmailTextField";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomTextField from "../../components/CustomTextField/CustomTextField";

const getLocalizedValue = (
  obj: Record<string, string>,
  language: string
): string => {
  if (obj[language]) {
    return obj[language];
  }
  return obj["en"] || "";
};

const PrivateEnrollPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const classId = searchParams.get("classID");
  const [self, setSelf] = useState<boolean | null>(null);
  const [selectedSchedules, setSelectedSchedules] = useState<number[]>([]);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | null>(
    null
  );
  const { language, setLanguage } = useLanguage();
  const [activeStep, setActiveStep] = useState(0);

  const { user } = useSelector((state: RootState) => state.auth);
  const classData = useSelector((state: RootState) => state.enrollment.class);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const langParam = searchParams.get("Lang");
    if (langParam && ["en", "fr", "uk"].includes(langParam.toLowerCase())) {
      const newLanguage = langParam.toLowerCase() as Language;
      if (newLanguage !== language) {
        setLanguage(newLanguage);
      }
    }

    dispatch(setFrom(null));
    if (classId) {
      dispatch(fetchClassPublic(Number(classId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, classId, setLanguage]);

  useEffect(() => {
    const currentLangParam = searchParams.get("Lang");
    if (currentLangParam !== language.toUpperCase()) {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set("Lang", language.toUpperCase());
      setSearchParams(newParams, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (!user.id) {
      navigate(`/enroll${window.location.search}`);
    }
  }, [user.id, navigate]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(
      intl.formatMessage({ id: "signUp.firstNameRequired" })
    ),
    lastName: Yup.string().required(
      intl.formatMessage({ id: "signUp.lastNameRequired" })
    ),
    birthdate: Yup.date().required(
      intl.formatMessage({ id: "privateEnroll.birthdateRequired" })
    ),
    sex: Yup.string().required(
      intl.formatMessage({ id: "privateEnroll.sexRequired" })
    ),
    phone: Yup.string()
      .required(intl.formatMessage({ id: "signUp.phoneRequired" }))
      .test(
        "is-valid-phone-number",
        intl.formatMessage({ id: "signUp.invalidPhoneNumber" }),
        (value) => isValidPhoneNumber(value)
      ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "signUp.invalidEmail" }))
      .required(intl.formatMessage({ id: "signUp.emailRequired" })),
    postalCode: Yup.string()
      .matches(
        /^[0-9]{5}(-[0-9]{4})?$/,
        intl.formatMessage({ id: "attendees.invalidPostalCodeFormat" })
      )
      .required(intl.formatMessage({ id: "signUp.postalCodeRequired" })),
  });

  const handleLogout = () => {
    dispatch(logOut());
  };

  const handleScheduleChange = (scheduleId: number) => {
    setSelectedSchedules((prev) =>
      prev.includes(scheduleId)
        ? prev.filter((id) => id !== scheduleId)
        : [...prev, scheduleId]
    );
  };

  const renderSchedules = () => {
    if (!classData || !classData.schedules) return null;

    const weekdays = [
      intl.formatMessage({ id: "days.monday" }),
      intl.formatMessage({ id: "days.tuesday" }),
      intl.formatMessage({ id: "days.wednesday" }),
      intl.formatMessage({ id: "days.thursday" }),
      intl.formatMessage({ id: "days.friday" }),
      intl.formatMessage({ id: "days.saturday" }),
      intl.formatMessage({ id: "days.sunday" }),
    ];
    const groupedSchedules: { [key: string]: Schedule[] } = {};

    classData.schedules
      .filter(
        (schedule) => !schedule.date || new Date(schedule?.date) > new Date()
      )
      .forEach((schedule) => {
        const day = weekdays[schedule.dayOfWeek];
        if (!groupedSchedules[day]) {
          groupedSchedules[day] = [];
        }
        groupedSchedules[day].push(schedule);
      });

    return weekdays
      .filter(
        (day) => groupedSchedules[day] && groupedSchedules[day].length > 0
      )
      .map((day) => (
        <Box key={day} sx={{ mb: 2 }}>
          <Typography variant="h6">{day}</Typography>
          {groupedSchedules[day].map((schedule) => (
            <FormControlLabel
              key={schedule.id}
              control={
                <>
                  <Checkbox
                    checked={selectedSchedules.includes(schedule.id)}
                    onChange={() => handleScheduleChange(schedule.id)}
                  />
                  {schedule.date && (
                    <FormattedDate
                      value={schedule.date}
                      year="numeric"
                      month="long"
                      day="numeric"
                    />
                  )}
                </>
              }
              label={`${schedule.date ? "/" : ""}${schedule.startTime} - ${
                schedule.endTime
              }`}
            />
          ))}
        </Box>
      ));
  };

  const handleCreateAttendee = async (
    values: Omit<Attendee, "id" | "userId">
  ) => {
    setError(null);
    try {
      const resultAction = await dispatch(
        createAttendee({
          ...values,
          birthdate: new Date(values.birthdate),
          isUser: Boolean(self),
        })
      ).unwrap();

      if (resultAction.id) {
        setSelectedAttendee(resultAction);
        setActiveStep(2);
      }
    } catch (error: any) {
      if (error.status === 409) {
        setError(intl.formatMessage({ id: "attendees.emailAlreadyExists" }));
      } else {
        setError(intl.formatMessage({ id: "common.unexpectedError" }));
      }
      console.error("Failed to create attendee:", error);
    }
  };

  const handleSelectExistingAttendee = (attendee: Attendee) => {
    setSelectedAttendee(attendee);
    setActiveStep(2);
  };

  const handleWho = (bool: boolean) => {
    let selfAtt;
    if (bool) {
      selfAtt = user.attendees.find((a) => a.isUser);
    }
    setSelf(bool);
    if (selfAtt) {
      setSelectedAttendee(selfAtt);
      setActiveStep(1);
    } else {
      setSelectedAttendee(null);
      setActiveStep(1);
    }
    setSelectedSchedules([]);
  };

  const handleUnselectAttendee = () => {
    setSelectedAttendee(null);
    if (self) {
      setSelf(null);
      setActiveStep(0);
    } else {
      setActiveStep(1);
    }
    setSelectedSchedules([]);
  };

  const steps = [
    {
      title: intl.formatMessage({ id: "privateEnroll.enrollingWho" }),
      content: (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Button
              onClick={() => handleWho(true)}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={classData?.status === "Cancelled"}
            >
              <FormattedMessage id="privateEnroll.self" defaultMessage="SELF" />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              onClick={() => handleWho(false)}
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              disabled={classData?.status === "Cancelled"}
            >
              <FormattedMessage
                id="privateEnroll.anotherPerson"
                defaultMessage="ANOTHER PERSON"
              />
            </Button>
          </Grid>
        </Grid>
      ),
    },
    {
      title: intl.formatMessage({ id: "privateEnroll.selectAttendee" }),
      content: (
        <>
          {selectedAttendee ? (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                <FormattedMessage
                  id="privateEnroll.selectedAttendee"
                  defaultMessage="Selected Attendee: {name}"
                  values={{
                    name: `${selectedAttendee.firstName} ${selectedAttendee.lastName}`,
                  }}
                />
              </Typography>
              <Button
                onClick={handleUnselectAttendee}
                variant="outlined"
                color="secondary"
                sx={{ mt: 1 }}
              >
                <FormattedMessage
                  id="privateEnroll.unselectAttendee"
                  defaultMessage="Unselect Attendee"
                />
              </Button>
            </Box>
          ) : (
            <>
              {Boolean(user.attendees.length) && self === false && (
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    <FormattedMessage
                      id="privateEnroll.existingAttendees"
                      defaultMessage="Existing Attendees"
                    />
                  </Typography>
                  <List>
                    {user.attendees.map((attendee) => (
                      <ListItem
                        key={attendee.id}
                        sx={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                          border: "1px solid #e0e0e0",
                          borderRadius: 2,
                          mb: 2,
                          p: 2,
                        }}
                      >
                        <ListItemText
                          primary={`${attendee.firstName} ${attendee.lastName}`}
                          secondary={
                            <FormattedMessage
                              id="privateEnroll.attendeeInfo"
                              defaultMessage="{email}: {emailValue} | {phone}: {phoneValue}"
                              values={{
                                email: intl.formatMessage({
                                  id: "signIn.emailLabel",
                                }),
                                emailValue: attendee.email,
                                phone: intl.formatMessage({
                                  id: "signUp.phone",
                                }),
                                phoneValue: attendee.phone,
                              }}
                            />
                          }
                        />
                        <Button
                          onClick={() => handleSelectExistingAttendee(attendee)}
                          variant="contained"
                          color="primary"
                          sx={{ mt: 1 }}
                        >
                          <FormattedMessage
                            id="privateEnroll.select"
                            defaultMessage="Select"
                          />
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}

              <Box>
                <Typography variant="h6" gutterBottom>
                  <FormattedMessage
                    id="privateEnroll.createNewAttendee"
                    defaultMessage={
                      self ? "Enroll Yourself" : "Create New Attendee"
                    }
                  />
                </Typography>
                <Formik
                  initialValues={{
                    firstName: self && user.firstname ? user.firstname : "",
                    lastName: self && user.lastname ? user.lastname : "",
                    birthdate: new Date(),
                    sex: "",
                    phone: self && user.phone ? user.phone : "",
                    email: self && user.email ? user.email : "",
                    postalCode: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleCreateAttendee}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                      {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                          {error}
                        </Alert>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={LatinTextField}
                            name="firstName"
                            label={intl.formatMessage({
                              id: "signUp.firstName.input",
                            })}
                            error={touched.firstName && errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={LatinTextField}
                            name="lastName"
                            label={intl.formatMessage({
                              id: "signUp.lastName.input",
                            })}
                            error={touched.lastName && errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="birthdate"
                            label={intl.formatMessage({
                              id: "admin.dashboard.birthdate",
                            })}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            error={touched.birthdate && errors.birthdate}
                            helperText={touched.birthdate && errors.birthdate}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {intl.formatMessage({
                                id: "admin.dashboard.sex",
                              })}
                            </FormLabel>
                            <Field
                              as={RadioGroup}
                              name="sex"
                              row
                              error={touched.sex && errors.sex}
                            >
                              <FormControlLabel
                                value="female"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "privateEnroll.female",
                                })}
                              />
                              <FormControlLabel
                                value="male"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "privateEnroll.male",
                                })}
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                label={intl.formatMessage({
                                  id: "privateEnroll.other",
                                })}
                              />
                            </Field>
                            <ErrorMessage name="sex">
                              {(msg) => (
                                <Typography
                                  color="error"
                                  variant="caption"
                                  component="div"
                                  style={{ marginTop: 4 }}
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div>
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              value={values.phone || ""}
                              onChange={(value) =>
                                setFieldValue("phone", value || "")
                              }
                              inputComponent={CustomTextField as any}
                            />
                            {touched.phone && errors.phone && (
                              <Typography color="error" variant="caption">
                                {errors.phone}
                              </Typography>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            as={EmailTextField}
                            name="email"
                            label={intl.formatMessage({ id: "signUp.email" })}
                            type="email"
                            error={touched.email && errors.email}
                            helperText={touched.email && errors.email}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            as={TextField}
                            name="postalCode"
                            label={intl.formatMessage({
                              id: "signUp.postalCode",
                            })}
                            error={touched.postalCode && errors.postalCode}
                            helperText={touched.postalCode && errors.postalCode}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                          >
                            <FormattedMessage
                              id="privateEnroll.createAttendee"
                              defaultMessage="Create Attendee"
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </>
          )}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: "privateEnroll.selectSchedules" }),
      content: (
        <>
          {renderSchedules()}

          {selectedAttendee && (
            <EnrollmentHandler
              attendeeId={selectedAttendee.id}
              scheduleIds={selectedSchedules}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Container maxWidth="md" sx={{ pb: 5 }}>
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <LanguageSelector />
        </Box>
        <Typography variant="h4" gutterBottom align="center">
          <FormattedMessage
            id="privateEnroll.title"
            defaultMessage="Enroll Details"
          />
        </Typography>

        {classId && (
          <Typography variant="body1" gutterBottom align="center">
            <FormattedMessage
              id="privateEnroll.enrollingFor"
              defaultMessage="Enrolling for {className} class"
              values={{
                className: classData?.title
                  ? getLocalizedValue(classData.title, language)
                  : "",
              }}
            />
          </Typography>
        )}

        {selectedAttendee && activeStep === 2 && (
          <Typography variant="h6" gutterBottom align="center">
            <FormattedMessage
              id="privateEnroll.selectedAttendee"
              defaultMessage="Selected Attendee: {name}"
              values={{
                name: selectedAttendee
                  ? `${selectedAttendee.firstName} ${selectedAttendee.lastName}`
                  : intl.formatMessage({
                      id: "privateEnroll.noAttendeeSelected",
                    }),
              }}
            />
          </Typography>
        )}

        {steps.map((step, index) => (
          <Accordion
            key={index}
            expanded={activeStep === index}
            onChange={() => setActiveStep(index)}
            disabled={index > activeStep && !selectedAttendee}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              "&:not(:last-child)": {
                borderBottom: 0,
              },
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, .125)",
                "&.Mui-expanded": {
                  minHeight: 56,
                },
              }}
            >
              <Typography variant="h6">{step.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{step.content}</AccordionDetails>
          </Accordion>
        ))}

        <Box sx={{ mt: 4 }}>
          <Button
            onClick={handleLogout}
            fullWidth
            variant="outlined"
            color="error"
            size="large"
          >
            <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivateEnrollPage;
