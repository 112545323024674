import React, { useState } from "react";
import { Field, FieldProps } from "formik";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

type PasswordFieldProps = TextFieldProps & {
  name: string;
  label: string;
};

const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Field name={props.name}>
      {({ field, meta, form }: FieldProps) => (
        <TextField
          {...field}
          {...props}
          type={showPassword ? "text" : "password"}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          InputProps={{
            ...(props.InputProps || {}),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onBlur={(event) => {
            const trimmedValue = event.target.value.trim();
            form.setFieldValue(field.name, trimmedValue);
            field.onBlur(event);
          }}
        />
      )}
    </Field>
  );
};

export default PasswordField;
