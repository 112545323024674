import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Checkbox,
  Divider,
  Box,
} from "@mui/material";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { AttendeeToSchedule, Schedule } from "../../../../redux/types/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { unenrollAttendeeFromSchedules } from "../../../../redux/enrollment/enrollmentOperations";
import { getProfile } from "../../../../redux/auth/authOperations";

interface AttendeeSchedulesModalProps {
  open: boolean;
  onClose: () => void;
  attendee: AttendeeToSchedule | null;
  schedulesList: Schedule[];
}

export default function AttendeeSchedulesModal({
  open,
  onClose,
  attendee,
  schedulesList,
}: AttendeeSchedulesModalProps) {
  const intl = useIntl();
  const [selectedSchedules, setSelectedSchedules] = useState<number[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const weekdays = [
    intl.formatMessage({ id: "days.monday" }),
    intl.formatMessage({ id: "days.tuesday" }),
    intl.formatMessage({ id: "days.wednesday" }),
    intl.formatMessage({ id: "days.thursday" }),
    intl.formatMessage({ id: "days.friday" }),
    intl.formatMessage({ id: "days.saturday" }),
    intl.formatMessage({ id: "days.sunday" }),
  ];

  const filteredSchedules = schedulesList.filter((schedule) => {
    return schedule.AttendeeToSchedule?.some(
      (a) => a.attendee.id === attendee?.attendee.id && !a.unenrollDate
    );
  });

  const groupedSchedules = filteredSchedules.reduce((acc, schedule) => {
    const day = schedule.dayOfWeek;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(schedule);
    return acc;
  }, {} as Record<number, Schedule[]>);

  const handleUnenrollAll = async () => {
    if (attendee?.attendee.id) {
      await dispatch(
        unenrollAttendeeFromSchedules({
          attendeeId: attendee.attendee.id,
          scheduleIds: filteredSchedules.map((s) => s.id),
        })
      );
      await dispatch(getProfile());
      setSelectedSchedules([]);
      onClose();
    }
  };

  const handleUnenrollSelected = async () => {
    if (attendee?.attendee.id) {
      await dispatch(
        unenrollAttendeeFromSchedules({
          attendeeId: attendee.attendee.id,
          scheduleIds: selectedSchedules,
        })
      );
      await dispatch(getProfile());
      setSelectedSchedules([]);
      onClose();
    }
  };

  const handleToggleSchedule = (scheduleId: number) => {
    setSelectedSchedules((prev) =>
      prev.includes(scheduleId)
        ? prev.filter((id) => id !== scheduleId)
        : [...prev, scheduleId]
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="attendeeSchedules.unenrollTitle"
          defaultMessage="Unenroll {name}"
          values={{
            name: `${attendee?.attendee.firstName} ${attendee?.attendee.lastName}`,
          }}
        />
      </DialogTitle>
      <DialogContent>
        <Box display="flex" gap={2}>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography gutterBottom>
              <FormattedMessage
                id="attendeeSchedules.quickUnenroll"
                defaultMessage="Quick unenroll from all schedules"
              />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUnenrollAll}
              sx={{ mt: 2 }}
            >
              <FormattedMessage
                id="attendeeSchedules.unenrollAll"
                defaultMessage="Unenroll from class"
              />
            </Button>
          </Box>

          <Divider orientation="vertical" flexItem />

          <Box flex={1}>
            <Typography gutterBottom>
              <FormattedMessage
                id="attendeeSchedules.selectSchedules"
                defaultMessage="Select schedules to unenroll from"
              />
            </Typography>
            {weekdays.map((day, index) => {
              const schedules = groupedSchedules[index];
              if (!schedules || schedules.length === 0) return null;
              return (
                <Box key={index} mb={2}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {day}
                  </Typography>
                  <List dense>
                    {schedules.map((schedule) => (
                      <ListItem key={schedule.id} dense>
                        <Checkbox
                          edge="start"
                          checked={selectedSchedules.includes(schedule.id)}
                          onChange={() => handleToggleSchedule(schedule.id)}
                        />
                        {schedule.date && (
                          <>
                            <FormattedDate
                              value={schedule.date}
                              year="numeric"
                              month="long"
                              day="numeric"
                            />
                            {"/"}
                          </>
                        )}
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              {`${schedule.startTime} - ${schedule.endTime}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              );
            })}
            <Button
              variant="contained"
              color="primary"
              onClick={handleUnenrollSelected}
              disabled={selectedSchedules.length === 0}
              fullWidth
              sx={{ mt: 2 }}
            >
              <FormattedMessage
                id="attendeeSchedules.unenrollSelected"
                defaultMessage="Unenroll from selected"
              />
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
