import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { AppDispatch } from "../../../redux/store";
import { createAttendeeToSchedules } from "../../../redux/enrollment/enrollmentOperations";
import { getProfile } from "../../../redux/auth/authOperations";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
} from "@mui/material";

interface EnrollmentHandlerProps {
  attendeeId: number;
  scheduleIds: number[];
}

export default function EnrollmentHandler({
  attendeeId,
  scheduleIds,
}: EnrollmentHandlerProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isWaitlist, setIsWaitlist] = useState(false);

  const handleEnroll = async () => {
    try {
      const result = await dispatch(
        createAttendeeToSchedules({ attendeeId, scheduleIds })
      ).unwrap();
      await dispatch(getProfile());

      if (result.waitingListEntries) {
        setIsWaitlist(true);
        setMessage(intl.formatMessage({ id: "enrollment.waitlistSuccess" }));
      } else {
        setMessage(intl.formatMessage({ id: "enrollment.success" }));
      }

      setIsError(false);
      setOpen(true);
    } catch (error: any) {
      let errorMessage = intl.formatMessage({
        id: "enrollmentModal.generalError",
      });

      if (error?.message) {
        setIsError(true);
        errorMessage = getErrorMessageKey(error.message);
      }

      setMessage(intl.formatMessage({ id: errorMessage }));
      setOpen(true);
    }
  };

  const getErrorMessageKey = (error: string | string[]): string => {
    const errorMsg = Array.isArray(error) ? error[0] : error;

    if (errorMsg.includes("Attendee is already enrolled in all schedules")) {
      return "enrollmentModal.alreadyEnrolledInAll";
    } else if (errorMsg.includes("Attendee is already enrolled")) {
      return "enrollmentModal.alreadyEnrolled";
    } else if (errorMsg.includes("One or more schedules are full")) {
      return "enrollmentModal.schedulesFull";
    } else if (errorMsg.includes("One or more schedules not found")) {
      return "enrollmentModal.schedulesNotFound";
    } else {
      return "enrollmentModal.generalError";
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (!isError) {
      navigate("/my-classes");
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleEnroll}>
        <FormattedMessage id="enrollment.enroll" defaultMessage="Enroll" />
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isError ? (
            <FormattedMessage
              id="enrollment.error.title"
              defaultMessage="Enrollment Error"
            />
          ) : isWaitlist ? (
            <FormattedMessage
              id="enrollment.waitlist.title"
              defaultMessage="Added to Waiting List"
            />
          ) : (
            <FormattedMessage
              id="enrollment.success.title"
              defaultMessage="Enrollment Successful"
            />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {isError ? (
              <FormattedMessage id="common.close" defaultMessage="Close" />
            ) : (
              <FormattedMessage
                id="enrollment.viewClasses"
                defaultMessage="View My Classes"
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isWaitlist}
        autoHideDuration={6000}
        onClose={() => setIsWaitlist(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => setIsWaitlist(false)} severity="info">
          <FormattedMessage
            id="enrollment.waitlistInfo"
            defaultMessage="You've been added to the waiting list for some classes."
          />
        </Alert>
      </Snackbar>
    </>
  );
}
