import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  PaletteMode,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";

import getLPTheme from "../../helpers/getLPTheme";
import HomeHeader from "../../components/HomeHeader/HomeHeader";

const HomePage = () => {
  const storedMode = localStorage.getItem("mode");
  const [mode, setMode] = React.useState<PaletteMode>(
    storedMode === "light" || storedMode === "dark" ? storedMode : "light"
  );
  const LPtheme = createTheme(getLPTheme(mode));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const toggleColorMode = async () => {
    localStorage.setItem("mode", mode === "dark" ? "light" : "dark");
    await setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const cardWidth = isMobile ? "100%" : isTablet ? "90%" : 1100;

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <HomeHeader mode={mode} toggleColorMode={toggleColorMode} />
      <Box
        sx={{
          bgcolor: "background.default",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: { xs: "60px", sm: "80px" },
          px: { xs: 2, sm: 3, md: 4 },
        }}
      >
        {[1, 2, 3].map((index) => (
          <Card key={index} sx={{ width: cardWidth, m: { xs: 1, sm: 2 } }}>
            <CardHeader
              avatar={
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={40}
                  height={40}
                />
              }
              action={null}
              title={
                <Skeleton
                  animation="wave"
                  height={10}
                  width="80%"
                  style={{ marginBottom: 6 }}
                />
              }
              subheader={<Skeleton animation="wave" height={10} width="40%" />}
            />
            <Skeleton
              sx={{ height: { xs: 150, sm: 190 } }}
              animation="wave"
              variant="rectangular"
            />
            <CardContent>
              <React.Fragment>
                <Skeleton
                  animation="wave"
                  height={10}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={10} width="80%" />
              </React.Fragment>
            </CardContent>
          </Card>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default HomePage;
