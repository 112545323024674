import React from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Alert,
  Button,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import LocationsList from "./LocationsList/LocationsList";
import { useLocationManagement } from "./useLocationManagement";
import DeleteLocationDialog from "./DeleteLocationDialog/DeleteLocationDialog";
import EditLocationDialog from "./EditLocationDialog/EditLocationDialog";
import CreateLocationDialog from "./CreateLocationDialog/CreateLocationDialog";

export const LocationManagement: React.FC = () => {
  const intl = useIntl();
  const {
    locations,
    totalLocations,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    locationToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    fetchLocationData,
    handleDeleteLocation,
    confirmDeleteLocation,
    handleEditLocation,
    handleUpdateLocation,
    handleCreateLocation,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setLocationToEdit,
  } = useLocationManagement();

  React.useEffect(() => {
    fetchLocationData();
  }, [fetchLocationData]);

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" component="div">
                <FormattedMessage id="admin.dashboard.locations" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SearchIcon sx={{ mr: 1 }} />
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={intl.formatMessage({
                    id: "admin.dashboard.searchPlaceholder",
                  })}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setCreateDialogOpen(true)}
          >
            <FormattedMessage id="admin.dashboard.createLocation" />
          </Button>
        </Box>
        <LocationsList
          locations={locations}
          totalLocations={totalLocations}
          page={page}
          rowsPerPage={rowsPerPage}
          orderBy={orderBy}
          order={order}
          onRequestSort={handleRequestSort}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onEditLocation={handleEditLocation}
          onDeleteLocation={handleDeleteLocation}
        />
      </Paper>
      <DeleteLocationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={confirmDeleteLocation}
      />
      <EditLocationDialog
        open={editDialogOpen}
        locationToEdit={locationToEdit}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleUpdateLocation}
        onLocationChange={setLocationToEdit}
      />
      <CreateLocationDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSave={handleCreateLocation}
      />
    </Box>
  );
};
