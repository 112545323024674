import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import { useIntl } from "react-intl";

const CustomTextField = forwardRef((props, ref) => {
  const intl = useIntl();
  return (
    <TextField
      {...props}
      inputRef={ref}
      required
      label={intl.formatMessage({ id: "signUp.phone" })}
    />
  );
});

export default CustomTextField;
