import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
  Autocomplete,
  Card,
  CardContent,
} from "@mui/material";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  getEnrollmentsReport,
  fetchInstructors,
  fetchClasses,
  fetchLocations,
  fetchTypes,
} from "../../../../redux/admin/adminOperations";
import {
  Instructor,
  Class,
  Type,
  Location,
  EnrollmentReportItem,
} from "../../../../redux/types/types";
import * as XLSX from "xlsx";

export default function EnrollmentsReports() {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { instructors, classes, types, locations, loading } = useSelector(
    (state: RootState) => state.admin
  );
  const { enrollmentsReport } = useSelector(
    (state: RootState) => state.admin.reports
  );
  const [selectedInstructor, setSelectedInstructor] =
    useState<Instructor | null>(null);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [selectedType, setSelectedType] = useState<Type | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(
      fetchInstructors({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchClasses({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchLocations({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
    dispatch(
      fetchTypes({
        page: 1,
        limit: 1000,
        sortBy: "id",
        sortOrder: "asc",
        search: "",
      })
    );
  }, [dispatch]);

  const filteredClasses = useMemo(() => {
    if (!selectedInstructor) return classes;
    return classes.filter((c) => c.instructor.id === selectedInstructor.id);
  }, [classes, selectedInstructor]);

  const filteredLocations = useMemo(() => {
    if (!selectedInstructor && !selectedClass) return locations;
    if (selectedClass) {
      return [selectedClass.location];
    }
    return locations.filter((l) =>
      filteredClasses.some((c) => c.location.id === l.id)
    );
  }, [locations, filteredClasses, selectedInstructor, selectedClass]);

  const filteredTypes = useMemo(() => {
    if (!selectedInstructor && !selectedClass) return types;
    if (selectedClass) {
      return [selectedClass.type];
    }
    return types.filter((t) => filteredClasses.some((c) => c.type.id === t.id));
  }, [types, filteredClasses, selectedInstructor, selectedClass]);

  const handleInstructorChange = (value: Instructor | null) => {
    setSelectedInstructor(value);
    setSelectedClass(null);
    setSelectedLocation(null);
    setSelectedType(null);
  };

  const handleClassChange = (value: Class | null) => {
    setSelectedClass(value);
    setSelectedLocation(value ? value.location : null);
    setSelectedType(value ? value.type : null);
  };

  const handleGenerateReport = () => {
    dispatch(
      getEnrollmentsReport({
        instructorId: selectedInstructor?.id?.toString(),
        classId: selectedClass?.id?.toString(),
        fromDate: fromDate || undefined,
        toDate: toDate || undefined,
        typeId: selectedType?.id?.toString(),
        locationId: selectedLocation?.id?.toString(),
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedReport = enrollmentsReport?.reportData
    ? enrollmentsReport.reportData.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage
      )
    : [];

  const getFormattedDateTime = () => {
    const now = new Date();
    return now.toISOString().replace(/[:.]/g, "-").slice(0, -5);
  };

  const handleExportToExcel = () => {
    if (!enrollmentsReport) return;

    const worksheet = XLSX.utils.json_to_sheet(
      enrollmentsReport.reportData.map((item: EnrollmentReportItem) => ({
        "Class Title": item.class.title.en,
        "Instructor Name": `${item.instructor.firstname} ${item.instructor.lastname}`,
        "Instructor Email": item.instructor.email,
        "Instructor Phone": item.instructor.phone,
        "Enrolled Count": item.enrolledCount,
        "Waiting List Count": item.waitingListCount,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrollments Report");
    const fileName = `enrollments_report_${getFormattedDateTime()}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        <FormattedMessage
          id="admin.dashboard.reports.enrollments.title"
          defaultMessage="Enrollments Report"
        />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={instructors}
            getOptionLabel={(option: Instructor) =>
              `${option.firstname} ${option.lastname}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "admin.dashboard.instructor",
                  defaultMessage: "Instructor",
                })}
                fullWidth
              />
            )}
            value={selectedInstructor}
            onChange={(event, newValue) => handleInstructorChange(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={filteredClasses}
            getOptionLabel={(option: Class) => option.title.en}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "admin.dashboard.class",
                  defaultMessage: "Class",
                })}
                fullWidth
              />
            )}
            value={selectedClass}
            onChange={(event, newValue) => handleClassChange(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={filteredTypes}
            getOptionLabel={(option: Type) => option.name.en}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "admin.dashboard.type",
                  defaultMessage: "Type",
                })}
                fullWidth
              />
            )}
            value={selectedType}
            onChange={(event, newValue) => setSelectedType(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            options={filteredLocations}
            getOptionLabel={(option: Location) => option.name.en}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "admin.dashboard.location",
                  defaultMessage: "Location",
                })}
                fullWidth
              />
            )}
            value={selectedLocation}
            onChange={(event, newValue) => setSelectedLocation(newValue)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label={intl.formatMessage({
              id: "admin.dashboard.reports.fromDate",
              defaultMessage: "From Date",
            })}
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label={intl.formatMessage({
              id: "admin.dashboard.reports.toDate",
              defaultMessage: "To Date",
            })}
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split("T")[0],
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            onClick={handleGenerateReport}
            disabled={loading}
            fullWidth
            sx={{ height: "100%" }}
          >
            <FormattedMessage
              id="admin.dashboard.reports.generate"
              defaultMessage="Generate Report"
            />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="outlined"
            onClick={handleExportToExcel}
            disabled={
              !enrollmentsReport || enrollmentsReport.reportData.length === 0
            }
            fullWidth
            sx={{ height: "100%" }}
          >
            <FormattedMessage
              id="admin.dashboard.reports.exportExcel"
              defaultMessage="Export to Excel"
            />
          </Button>
        </Grid>
      </Grid>
      {enrollmentsReport && (
        <>
          <Card sx={{ mt: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <FormattedMessage
                  id="admin.dashboard.reports.enrollments.summary"
                  defaultMessage="Summary"
                />
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography>
                    <FormattedMessage
                      id="admin.dashboard.reports.enrollments.totalEnrolled"
                      defaultMessage="Total Enrolled:"
                    />{" "}
                    {enrollmentsReport.summary.totalEnrolled}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <FormattedMessage
                      id="admin.dashboard.reports.enrollments.totalWaiting"
                      defaultMessage="Total Waiting:"
                    />{" "}
                    {enrollmentsReport.summary.totalWaitingList}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="admin.dashboard.class"
                      defaultMessage="Class"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="admin.dashboard.instructor"
                      defaultMessage="Instructor"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="admin.dashboard.reports.enrollments.enrolledCount"
                      defaultMessage="Enrolled Count"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="admin.dashboard.reports.enrollments.waitingListCount"
                      defaultMessage="Waiting List Count"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedReport.map(
                  (item: EnrollmentReportItem, index: number) => (
                    <TableRow key={`${item.class.id}-${index}`}>
                      <TableCell>{item.class.title.en}</TableCell>
                      <TableCell>{`${item.instructor.firstname} ${item.instructor.lastname}`}</TableCell>
                      <TableCell>{item.enrolledCount}</TableCell>
                      <TableCell>{item.waitingListCount}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={enrollmentsReport.reportData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={intl.formatMessage({
                id: "admin.dashboard.rowsPerPage",
                defaultMessage: "Rows per page:",
              })}
            />
          </TableContainer>
        </>
      )}
    </Box>
  );
}
