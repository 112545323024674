import api from "./axiosInstance";

export const fetchLocationsApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) => {
  return api.get("/admin/locations", {
    params: params,
  });
};

export const createLocationApi = (locationData: any) =>
  api.post("/admin/locations", locationData);

export const deleteLocationApi = (locationId: number) =>
  api.delete(`/admin/locations/${locationId}`);

export const getLocationApi = (locationId: number) =>
  api.get(`/admin/locations/${locationId}`);

export const updateLocationApi = (locationId: number, locationData: any) =>
  api.patch(`/admin/locations/${locationId}`, locationData);
