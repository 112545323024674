import React from "react";
import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

interface LatinTextFieldProps {
  name: string;
  label: string;
  [key: string]: any;
}

export const LatinTextField: React.FC<LatinTextFieldProps> = ({
  name,
  label,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^A-Za-z\s]/g, "");
    setFieldValue(name, value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.trim();
    setFieldValue(name, trimmedValue);
    field.onBlur(e);
  };

  return (
    <TextField
      {...field}
      {...props}
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export default LatinTextField;
