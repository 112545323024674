import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Grid,
} from "@mui/material";
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import {
  uploadClassImages,
  deleteClassImage,
} from "../../../../redux/admin/adminOperations";
import { RootState, AppDispatch } from "../../../../redux/store";

interface ClassImagesProps {
  classId: number;
}

const getErrorMessageId = (error: any): string => {
  if (error) {
    switch (error.statusCode) {
      case 400:
        if (error.message === "No files uploaded") {
          return "admin.dashboard.error.noFilesUploaded";
        }
        if (error.message === "Only image files are allowed!") {
          return "admin.dashboard.error.onlyImagesAllowed";
        }
        return "admin.dashboard.error.badRequest";
      case 401:
        return "admin.dashboard.error.unauthorized";
      case 403:
        return "admin.dashboard.error.forbidden";
      case 404:
        return "admin.dashboard.error.notFound";
      case 413:
        return "admin.dashboard.error.fileTooLarge";
      default:
        return "admin.dashboard.error.serverError";
    }
  }
  return "admin.dashboard.error.networkError";
};

export default function ClassImages({ classId }: ClassImagesProps) {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const classData = useSelector((state: RootState) =>
    state.admin.classes.find((c) => c.id === classId)
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length > 0) {
      handleUpload(files);
    }
  };

  const handleUpload = (files: File[]) => {
    setIsUploading(true);
    setUploadError(null);
    dispatch(uploadClassImages({ classId, images: files }))
      .unwrap()
      .then(() => {
        setIsUploading(false);
      })
      .catch((error: any) => {
        setIsUploading(false);
        const messageId = getErrorMessageId(error);
        setUploadError(intl.formatMessage({ id: messageId }));
        console.error("Upload error:", error);
      });
  };

  const handleDeleteImage = (imageUrl: string) => {
    setIsDeleting(true);
    setDeleteError(null);
    dispatch(deleteClassImage({ classId, imageUrl }))
      .unwrap()
      .then(() => {
        setIsDeleting(false);
      })
      .catch((error: any) => {
        setIsDeleting(false);
        const messageId = getErrorMessageId(error);
        setDeleteError(intl.formatMessage({ id: messageId }));
        console.error("Delete error:", error);
      });
  };

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      handleUpload(files);
    }
    //eslint-disable-next-line
  }, []);

  if (!classData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        <FormattedMessage id="admin.dashboard.classImages" />
      </Typography>

      <Box
        sx={{
          border: "2px dashed",
          borderColor: isDragging ? "primary.main" : "#cccccc",
          borderRadius: 2,
          p: 2,
          mb: 2,
          textAlign: "center",
          cursor: "pointer",
          transition: "border-color 0.2s",
          "&:hover": {
            borderColor: "primary.main",
          },
        }}
        onClick={() => fileInputRef.current?.click()}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          multiple
          accept="image/*"
          onChange={handleFileChange}
        />
        <Typography>
          <FormattedMessage id="admin.dashboard.clickToUpload" />
        </Typography>
        <IconButton color="primary" component="span">
          <AddIcon />
        </IconButton>
      </Box>

      {isUploading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      {uploadError && (
        <Typography color="error" sx={{ mb: 2 }}>
          {uploadError}
        </Typography>
      )}

      {deleteError && (
        <Typography color="error" sx={{ mb: 2 }}>
          {deleteError}
        </Typography>
      )}

      <Grid container spacing={2}>
        {classData.images && classData.images.length > 0 ? (
          classData.images.map((imageUrl, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "100%",
                  "&:hover .deleteButton": {
                    opacity: 1,
                  },
                }}
              >
                <img
                  src={`${process.env.REACT_APP_API_URL}/${imageUrl}`}
                  alt={`Class ${index + 1}`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
                <IconButton
                  className="deleteButton"
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    opacity: 0,
                    transition: "opacity 0.2s",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    },
                  }}
                  onClick={() => handleDeleteImage(imageUrl)}
                  disabled={isDeleting}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography color="textSecondary">
              <FormattedMessage id="admin.dashboard.noImagesUploaded" />
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
