import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { forgotPasswordApi } from "../../../utils/api/auth";

const ForgotPassword = () => {
  const [serverError, setServerError] = useState<string | null>(null);
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage({ id: "forgotPassword.invalidEmail" }))
      .required(intl.formatMessage({ id: "forgotPassword.emailRequired" })),
  });

  const handleSubmit = async (
    values: { email: string },
    { setSubmitting, setStatus }: any
  ) => {
    try {
      await forgotPasswordApi(values.email);
      setStatus({ success: true });
      setServerError(null);
      setTimeout(() => navigate("/auth/signin"), 3000);
    } catch (error: any) {
      setStatus({ success: false });
      if (error.response) {
        switch (error.response.status) {
          case 404:
            setServerError(
              intl.formatMessage({ id: "forgotPassword.userNotFound" })
            );
            break;
          case 500:
            setServerError(
              intl.formatMessage({ id: "forgotPassword.internalServerError" })
            );
            break;
          default:
            setServerError(
              intl.formatMessage({ id: "forgotPassword.failedToSendEmail" })
            );
        }
      } else {
        setServerError(
          intl.formatMessage({ id: "forgotPassword.failedToSendEmail" })
        );
      }
    }
    setSubmitting(false);
  };

  return (
    <Box
      sx={{
        my: isMobile ? 4 : 12,
        mx: isMobile ? 2 : 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5">
        <FormattedMessage id="forgotPassword.title" />
      </Typography>
      {serverError && (
        <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
          {serverError}
        </Alert>
      )}
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, status }) => (
          <Form noValidate style={{ width: "100%" }}>
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({ id: "forgotPassword.emailAddress" })}
              name="email"
              autoComplete="email"
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="forgotPassword.resetButton" />
            </Button>
            {status && status.success && (
              <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
                <FormattedMessage id="forgotPassword.successMessage" />
              </Alert>
            )}
          </Form>
        )}
      </Formik>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Link to="/auth/signin" style={{ color: "#1976d2", fontSize: "14px" }}>
          <FormattedMessage id="forgotPassword.backToSignIn" />
        </Link>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
