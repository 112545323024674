import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TablePagination,
  Typography,
  Box,
  Chip,
  Collapse,
  Popover,
  List,
  ListItem,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  PeopleAlt as PeopleAltIcon,
  Cancel as CancelIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import ScheduleList from "./ScheduleList";
import { Class, Attendee } from "../../../../redux/types/types";

interface ClassesListProps {
  classes: Class[];
  totalClasses: number;
  page: number;
  rowsPerPage: number;
  orderBy: keyof Class | any;
  order: "asc" | "desc";
  onRequestSort: (property: keyof Class) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditClass: (classItem: Class) => void;
  onDeleteClass: (classId: number) => void;
  onSetClassInProgressOfCancellation: (
    classId: number,
    cancelDate: string
  ) => void;
  onReactivateClass: (classId: number) => void;
}

const languages = ["en", "uk", "fr"] as const;
type Language = (typeof languages)[number];

const LocalizedContent: React.FC<{ content: Record<Language, string> }> = ({
  content,
}) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    {languages.map(
      (lang) =>
        content[lang] && (
          <Box
            key={lang}
            sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
          >
            <Chip
              label={lang.toUpperCase()}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ width: 40, mr: 1, flexShrink: 0 }}
            />
            <Typography variant="body2" component="span">
              {content[lang]}
            </Typography>
          </Box>
        )
    )}
  </Box>
);

export default function ClassesList({
  classes,
  totalClasses,
  page,
  rowsPerPage,
  orderBy,
  order,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  onEditClass,
  onDeleteClass,
  onSetClassInProgressOfCancellation,
  onReactivateClass,
}: ClassesListProps) {
  const [expandedClass, setExpandedClass] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [waitingAttendees, setWaitingAttendees] = useState<Attendee[]>([]);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null);
  const [cancelDate, setCancelDate] = useState("");

  const intl = useIntl();

  const createSortHandler =
    (property: keyof Class) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  const handleExpandClick = (classId: number) => {
    setExpandedClass(expandedClass === classId ? null : classId);
  };

  const handleWaitingListClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    attendees: Attendee[]
  ) => {
    setAnchorEl(event.currentTarget);
    setWaitingAttendees(attendees);
  };

  const handleWaitingListClose = () => {
    setAnchorEl(null);
  };

  const handleCancelClick = (classId: number) => {
    setSelectedClassId(classId);
    setCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
    setSelectedClassId(null);
    setCancelDate("");
  };

  const handleCancelSubmit = () => {
    if (selectedClassId && cancelDate) {
      onSetClassInProgressOfCancellation(selectedClassId, cancelDate);
      handleCancelDialogClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "waiting-list-popover" : undefined;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={createSortHandler("title")}
                >
                  <FormattedMessage id="admin.dashboard.class.title" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.location" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.type" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.instructor" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.class.status" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classes.map((classItem) => (
              <React.Fragment key={classItem.id}>
                <TableRow>
                  <TableCell>
                    <IconButton
                      onClick={() => handleExpandClick(classItem.id)}
                      size="small"
                    >
                      {expandedClass === classItem.id ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.title} />
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.location.name} />
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.type.name} />
                  </TableCell>
                  <TableCell>
                    {`${classItem.instructor.firstname} ${classItem.instructor.lastname}`}
                  </TableCell>
                  <TableCell>
                    {classItem.status === "Active" && (
                      <Chip
                        label={intl.formatMessage({
                          id: "admin.dashboard.class.status.active",
                        })}
                        color="success"
                      />
                    )}
                    {classItem.status === "InProgressOfCancellation" && (
                      <Chip
                        label={intl.formatMessage({
                          id: "admin.dashboard.class.status.cancelling",
                        })}
                        color="warning"
                      />
                    )}
                    {classItem.status === "Cancelled" && (
                      <Chip
                        label={intl.formatMessage({
                          id: "admin.dashboard.class.status.cancelled",
                        })}
                        color="error"
                      />
                    )}
                    {classItem.status === "Active" && (
                      <IconButton
                        color="warning"
                        onClick={() => handleCancelClick(classItem.id)}
                        size="small"
                      >
                        <CancelIcon />
                      </IconButton>
                    )}
                    {classItem.status === "Cancelled" && (
                      <IconButton
                        color="success"
                        onClick={() => onReactivateClass(classItem.id)}
                        size="small"
                      >
                        <RefreshIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => onEditClass(classItem)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => onDeleteClass(classItem.id)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="default"
                      onClick={(event) =>
                        handleWaitingListClick(
                          event,
                          classItem.waitingAttendees || []
                        )
                      }
                      size="small"
                      disabled={
                        !classItem.waitingAttendees ||
                        classItem.waitingAttendees.length === 0
                      }
                    >
                      <PeopleAltIcon />
                      {classItem.waitingAttendees &&
                        classItem.waitingAttendees.length > 0 && (
                          <Typography
                            variant="caption"
                            component="span"
                            sx={{
                              position: "absolute",
                              top: -8,
                              right: -8,
                              backgroundColor: "primary.main",
                              color: "primary.contrastText",
                              borderRadius: "50%",
                              width: 16,
                              height: 16,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "0.75rem",
                            }}
                          >
                            {classItem.waitingAttendees.length}
                          </Typography>
                        )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                  >
                    <Collapse
                      in={expandedClass === classItem.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <ScheduleList schedules={classItem.schedules} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalClasses}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={<FormattedMessage id="admin.dashboard.rowsPerPage" />}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleWaitingListClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ minWidth: 150, maxWidth: 350 }}>
          <ListItem>
            <ListItemText
              primary={<FormattedMessage id="admin.dashboard.waitingList" />}
              primaryTypographyProps={{
                variant: "subtitle1",
                fontWeight: "bold",
              }}
            />
          </ListItem>
          {waitingAttendees.map((attendee, index) => (
            <ListItem key={attendee.id}>
              <ListItemText
                primary={`${index + 1}. ${attendee.firstName} ${
                  attendee.lastName
                }`}
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    <FormattedDate
                      value={attendee.createdAt}
                      year="numeric"
                      month="long"
                      day="numeric"
                    />
                  </Typography>
                }
              />
            </ListItem>
          ))}
          {waitingAttendees.length === 0 && (
            <ListItem>
              <ListItemText
                primary={
                  <FormattedMessage id="admin.dashboard.noWaitingAttendees" />
                }
              />
            </ListItem>
          )}
        </List>
      </Popover>
      <Dialog open={cancelDialogOpen} onClose={handleCancelDialogClose}>
        <DialogTitle>
          <FormattedMessage id="admin.dashboard.class.cancelTitle" />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="cancelDate"
            label={intl.formatMessage({
              id: "admin.dashboard.class.cancelDate",
            })}
            type="date"
            fullWidth
            variant="outlined"
            value={cancelDate}
            onChange={(e) => setCancelDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button onClick={handleCancelSubmit} color="primary">
            <FormattedMessage id="admin.dashboard.class.submitCancellation" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
