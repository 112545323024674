import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchUsers,
  deleteUser,
  updateUser,
  fetchInstructors,
  deleteInstructor,
  updateInstructor,
  createInstructor,
  fetchLocations,
  deleteLocation,
  updateLocation,
  createLocation,
  fetchTypes,
  deleteType,
  updateType,
  createType,
  fetchClasses,
  deleteClass,
  updateClass,
  createClass,
  fetchSchedules,
  deleteSchedule,
  createSchedule,
  updateSchedule,
  bulkCreateSchedules,
  bulkUpdateSchedules,
  bulkDeleteSchedules,
  fetchAttendees,
  deleteAttendee,
  createAttendee,
  updateAttendee,
  fetchAttendeesForUser,
  getAttendeesToSchedule,
  uploadClassImages,
  deleteClassImage,
  setClassInProgressOfCancellation,
  reactivateClass,
  getClassReport,
  getInstructorClassHoursReport,
  getEnrollmentsReport,
} from "./adminOperations";
import {
  AdminState,
  Location,
  Attendee,
  Type,
  Schedule,
  User,
  Instructor,
  Class,
} from "../types/types";

const initialState: AdminState = {
  users: [],
  totalUsers: 0,
  instructors: [],
  totalInstructors: 0,
  locations: [],
  totalLocations: 0,
  types: [],
  totalTypes: 0,
  classes: [],
  totalClasses: 0,
  schedules: [],
  totalSchedules: 0,
  attendees: [],
  totalAttendees: 0,
  loading: false,
  error: null,
  reports: {
    attendeesReports: null,
    enrollmentsReport: null,
    instructorClassHoursReport: null,
  },
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUsers.fulfilled,
        (state, action: PayloadAction<{ users: User[]; total: number }>) => {
          state.users = action.payload.users;
          state.totalUsers = action.payload.total;
          state.loading = false;
        }
      )
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch users";
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<number>) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
        state.totalUsers -= 1;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })

      // Instructors
      .addCase(fetchInstructors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchInstructors.fulfilled,
        (
          state,
          action: PayloadAction<{ instructors: Instructor[]; total: number }>
        ) => {
          state.instructors = action.payload.instructors;
          state.totalInstructors = action.payload.total;
          state.loading = false;
        }
      )
      .addCase(fetchInstructors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch instructors";
      })
      .addCase(
        deleteInstructor.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.instructors = state.instructors.filter(
            (instructor) => instructor.id !== action.payload
          );
          state.totalInstructors -= 1;
        }
      )
      .addCase(
        updateInstructor.fulfilled,
        (state, action: PayloadAction<Instructor>) => {
          const index = state.instructors.findIndex(
            (instructor) => instructor.id === action.payload.id
          );
          if (index !== -1) {
            state.instructors[index] = action.payload;
          }
        }
      )
      .addCase(
        createInstructor.fulfilled,
        (state, action: PayloadAction<Instructor>) => {
          state.instructors.push(action.payload);
          state.totalInstructors += 1;
        }
      )

      // Locations
      .addCase(fetchLocations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchLocations.fulfilled,
        (
          state,
          action: PayloadAction<{ locations: Location[]; total: number }>
        ) => {
          state.locations = action.payload.locations;
          state.totalLocations = action.payload.total;
          state.loading = false;
        }
      )
      .addCase(fetchLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch locations";
      })
      .addCase(
        deleteLocation.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.locations = state.locations.filter(
            (location) => location.id !== action.payload
          );
          state.totalLocations -= 1;
        }
      )
      .addCase(
        updateLocation.fulfilled,
        (state, action: PayloadAction<Location>) => {
          const index = state.locations.findIndex(
            (location) => location.id === action.payload.id
          );
          if (index !== -1) {
            state.locations[index] = action.payload;
          }
        }
      )
      .addCase(
        createLocation.fulfilled,
        (state, action: PayloadAction<Location>) => {
          state.locations.push(action.payload);
          state.totalLocations += 1;
        }
      )

      // Types
      .addCase(fetchTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTypes.fulfilled,
        (state, action: PayloadAction<{ types: Type[]; total: number }>) => {
          state.types = action.payload.types;
          state.totalTypes = action.payload.total;
          state.loading = false;
        }
      )
      .addCase(fetchTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch types";
      })
      .addCase(deleteType.fulfilled, (state, action: PayloadAction<number>) => {
        state.types = state.types.filter((type) => type.id !== action.payload);
        state.totalTypes -= 1;
      })
      .addCase(updateType.fulfilled, (state, action: PayloadAction<Type>) => {
        const index = state.types.findIndex(
          (type) => type.id === action.payload.id
        );
        if (index !== -1) {
          state.types[index] = action.payload;
        }
      })
      .addCase(createType.fulfilled, (state, action: PayloadAction<Type>) => {
        state.types.push(action.payload);
        state.totalTypes += 1;
      })

      // Classes
      .addCase(fetchClasses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchClasses.fulfilled,
        (state, action: PayloadAction<{ classes: Class[]; total: number }>) => {
          state.classes = action.payload.classes;
          state.totalClasses = action.payload.total;
          state.loading = false;
        }
      )
      .addCase(fetchClasses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch classes";
      })
      .addCase(
        deleteClass.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.classes = state.classes.filter(
            (classItem) => classItem.id !== action.payload
          );
          state.totalClasses -= 1;
        }
      )
      .addCase(updateClass.fulfilled, (state, action: PayloadAction<Class>) => {
        const index = state.classes.findIndex(
          (classItem) => classItem.id === action.payload.id
        );
        if (index !== -1) {
          state.classes[index] = action.payload;
        }
      })
      .addCase(createClass.fulfilled, (state, action: PayloadAction<Class>) => {
        state.classes.push(action.payload);
        state.totalClasses += 1;
      })

      // Schedules
      .addCase(fetchSchedules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSchedules.fulfilled,
        (
          state,
          action: PayloadAction<{ schedules: Schedule[]; total: number }>
        ) => {
          state.schedules = action.payload.schedules;
          state.totalSchedules = action.payload.total;
          state.loading = false;
        }
      )
      .addCase(fetchSchedules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch schedules";
      })
      .addCase(
        deleteSchedule.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.schedules = state.schedules.filter(
            (schedule) => schedule.id !== action.payload
          );
          state.totalSchedules -= 1;
        }
      )
      .addCase(
        createSchedule.fulfilled,
        (state, action: PayloadAction<Schedule>) => {
          state.schedules.push(action.payload);
          state.totalSchedules += 1;
        }
      )
      .addCase(
        updateSchedule.fulfilled,
        (state, action: PayloadAction<Schedule>) => {
          const index = state.schedules.findIndex(
            (schedule) => schedule.id === action.payload.id
          );
          if (index !== -1) {
            state.schedules[index] = action.payload;
          }
        }
      )
      .addCase(
        bulkCreateSchedules.fulfilled,
        (state, action: PayloadAction<Schedule[]>) => {
          state.schedules = [...state.schedules, ...action.payload];
          state.totalSchedules += action.payload.length;
        }
      )
      .addCase(
        bulkUpdateSchedules.fulfilled,
        (state, action: PayloadAction<Schedule[]>) => {
          action.payload.forEach((updatedSchedule) => {
            const index = state.schedules.findIndex(
              (schedule) => schedule.id === updatedSchedule.id
            );
            if (index !== -1) {
              state.schedules[index] = updatedSchedule;
            }
          });
        }
      )
      .addCase(
        bulkDeleteSchedules.fulfilled,
        (state, action: PayloadAction<number[]>) => {
          state.schedules = state.schedules.filter(
            (schedule) => !action.payload.includes(schedule.id)
          );
          state.totalSchedules -= action.payload.length;
        }
      )

      // Attendees
      .addCase(fetchAttendees.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAttendees.fulfilled,
        (
          state,
          action: PayloadAction<{ attendees: Attendee[]; total: number }>
        ) => {
          state.attendees = action.payload.attendees;
          state.totalAttendees = action.payload.total;
          state.loading = false;
        }
      )
      .addCase(fetchAttendees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch attendees";
      })
      .addCase(fetchAttendeesForUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAttendeesForUser.fulfilled,
        (state, action: PayloadAction<Attendee[]>) => {
          state.attendees = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchAttendeesForUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch attendees";
      })
      .addCase(
        deleteAttendee.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.attendees = state.attendees.filter(
            (attendee) => attendee.id !== action.payload
          );
          state.totalAttendees -= 1;
        }
      )
      .addCase(
        createAttendee.fulfilled,
        (state, action: PayloadAction<Attendee>) => {
          state.attendees.push(action.payload);
          state.totalAttendees += 1;
        }
      )
      .addCase(
        updateAttendee.fulfilled,
        (state, action: PayloadAction<Attendee>) => {
          const index = state.attendees.findIndex(
            (attendee) => attendee.id === action.payload.id
          );
          if (index !== -1) {
            state.attendees[index] = action.payload;
          }
        }
      )
      .addCase(getAttendeesToSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAttendeesToSchedule.fulfilled, (state, action: any) => {
        state.attendees = action.payload;
        state.loading = false;
      })
      .addCase(getAttendeesToSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch attendees";
      })

      // Images
      .addCase(uploadClassImages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        uploadClassImages.fulfilled,
        (
          state,
          action: PayloadAction<{
            message: string;
            imageUrls: string[];
            classId: number;
          }>
        ) => {
          state.loading = false;
          const classIndex = state.classes.findIndex(
            (c) => c.id === action.payload.classId
          );
          if (classIndex !== -1) {
            if (state.classes[classIndex].images) {
              state.classes[classIndex].images = [
                ...state.classes[classIndex].images!,
                ...action.payload.imageUrls,
              ];
            } else {
              state.classes[classIndex].images = action.payload.imageUrls;
            }
          }
        }
      )
      .addCase(uploadClassImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to upload images";
      })
      .addCase(deleteClassImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteClassImage.fulfilled,
        (
          state,
          action: PayloadAction<{
            message: string;
            classId: number;
            imageUrl: string;
          }>
        ) => {
          state.loading = false;
          const classIndex = state.classes.findIndex(
            (c) => c.id === action.payload.classId
          );
          if (classIndex !== -1 && state.classes[classIndex].images) {
            state.classes[classIndex].images = state.classes[
              classIndex
            ].images!.filter((url) => url !== action.payload.imageUrl);
          }
        }
      )
      .addCase(deleteClassImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete image";
      })

      // Status
      .addCase(
        setClassInProgressOfCancellation.fulfilled,
        (state, action: PayloadAction<Class>) => {
          const index = state.classes.findIndex(
            (classItem) => classItem.id === action.payload.id
          );
          if (index !== -1) {
            state.classes[index] = action.payload;
          }
        }
      )
      .addCase(
        reactivateClass.fulfilled,
        (state, action: PayloadAction<Class>) => {
          const index = state.classes.findIndex(
            (classItem) => classItem.id === action.payload.id
          );
          if (index !== -1) {
            state.classes[index] = action.payload;
          }
        }
      )

      // Report
      .addCase(getClassReport.pending, (state: AdminState) => {
        state.loading = true;
      })
      .addCase(
        getClassReport.fulfilled,
        (state: AdminState, action: PayloadAction<any>) => {
          state.loading = false;
          state.reports.attendeesReports = action.payload;
        }
      )
      .addCase(getClassReport.rejected, (state: AdminState) => {
        state.loading = false;
      })
      .addCase(getInstructorClassHoursReport.pending, (state: AdminState) => {
        state.loading = true;
      })
      .addCase(
        getInstructorClassHoursReport.fulfilled,
        (state: AdminState, action: PayloadAction<any>) => {
          state.loading = false;
          state.reports.instructorClassHoursReport = action.payload;
        }
      )
      .addCase(getInstructorClassHoursReport.rejected, (state: AdminState) => {
        state.loading = false;
      })
      .addCase(getEnrollmentsReport.pending, (state: AdminState) => {
        state.loading = true;
      })
      .addCase(
        getEnrollmentsReport.fulfilled,
        (state: AdminState, action: PayloadAction<any>) => {
          state.loading = false;
          state.reports.enrollmentsReport = action.payload;
        }
      )
      .addCase(getEnrollmentsReport.rejected, (state: AdminState) => {
        state.loading = false;
      });
  },
});

export default adminSlice.reducer;
