import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

interface DeleteInstructorDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function DeleteInstructorDialog({
  open,
  onClose,
  onConfirm,
}: DeleteInstructorDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id="admin.dashboard.deleteInstructorConfirmTitle" />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage id="admin.dashboard.deleteInstructorConfirmMessage" />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        <Button onClick={onConfirm} autoFocus>
          <FormattedMessage id="admin.dashboard.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
