import api from "./axiosInstance";

export const fetchClassesApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) => {
  return api.get("/admin/classes", {
    params: params,
  });
};

export const createClassApi = (classData: any) =>
  api.post("/admin/classes", classData);

export const deleteClassApi = (classId: number) =>
  api.delete(`/admin/classes/${classId}`);

export const getClassApi = (classId: number) =>
  api.get(`/admin/classes/${classId}`);

export const updateClassApi = (classId: number, classData: any) =>
  api.patch(`/admin/classes/${classId}`, classData);
