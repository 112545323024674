import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  // IconButton,
  Typography,
} from "@mui/material";
// import { Close as CloseIcon } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Attendee,
  Schedule,
  AttendeeToSchedule,
} from "../../../redux/types/types";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
// import { removeAttendeeSchedulesForClass } from "../../../redux/enrollment/enrollmentOperations";
// import { getProfile } from "../../../redux/auth/authOperations";
import AttendeeSchedulesModal from "./AttendeeSchedulesModal/AttendeeSchedulesModal";

interface AttendeesListProps {
  attendeesList: Attendee[];
  classId: number;
  schedulesList: Schedule[];
}

export default function AttendeesList({
  attendeesList,
  classId,
  schedulesList,
}: AttendeesListProps) {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );

  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [selectedAttendee, setSelectedAttendee] =
    useState<AttendeeToSchedule | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  // const handleRemoveAttendee = async (attendeeId: number) => {
  //   await dispatch(removeAttendeeSchedulesForClass({ attendeeId, classId }));
  //   await setAttendees((prev) => prev.filter((a) => a.id !== attendeeId));
  //   await dispatch(getProfile());
  // };

  const handleAttendeeClick = (attendee: Attendee) => {
    if (!isInstructor) {
      setSelectedAttendee({
        attendee,
        createdAt: new Date().toISOString(),
        unenrollDate: null,
      });
      setModalOpen(true);
    }
  };

  useEffect(() => {
    setAttendees(attendeesList);
  }, [attendeesList, dispatch]);

  return (
    <>
      <List sx={{ minWidth: 150, maxWidth: 350, maxHeight: "50vh" }}>
        <ListItem>
          <ListItemText
            primary={intl.formatMessage({
              id: "myClasses.attendeesList",
              defaultMessage: "Attendees List",
            })}
            primaryTypographyProps={{
              variant: "subtitle1",
              fontWeight: "bold",
            }}
          />
        </ListItem>
        {attendees.map((attendee, index) => (
          <ListItem
            key={attendee.id}
            // secondaryAction={
            //   isInstructor && (
            //     <IconButton
            //       edge="end"
            //       aria-label="remove"
            //       onClick={() => handleRemoveAttendee(attendee.id)}
            //     >
            //       <CloseIcon />
            //     </IconButton>
            //   )
            // }
            onClick={() => handleAttendeeClick(attendee)}
            sx={{ cursor: isInstructor ? "default" : "pointer" }}
          >
            <ListItemText
              primary={
                <Typography>
                  {`${index + 1}. ${attendee.firstName} ${attendee.lastName}`}
                </Typography>
              }
              secondary={`${calculateAge(attendee.birthdate)} years old, ${
                attendee.sex
              }`}
            />
          </ListItem>
        ))}
        {attendeesList.length === 0 && (
          <ListItem>
            <ListItemText
              primary={
                <FormattedMessage
                  id="myClasses.noAttendees"
                  defaultMessage="No attendees"
                />
              }
            />
          </ListItem>
        )}
      </List>
      <AttendeeSchedulesModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        attendee={selectedAttendee}
        schedulesList={schedulesList}
      />
    </>
  );
}

function calculateAge(birthdate: Date): number {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
