import api from "./axiosInstance";

export const fetchTypesApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) => {
  return api.get("/admin/types", {
    params: params,
  });
};

export const createTypeApi = (typeData: any) =>
  api.post("/admin/types", typeData);

export const deleteTypeApi = (typeId: number) =>
  api.delete(`/admin/types/${typeId}`);

export const getTypeApi = (typeId: number) => api.get(`/admin/types/${typeId}`);

export const updateTypeApi = (typeId: number, typeData: any) =>
  api.patch(`/admin/types/${typeId}`, typeData);
