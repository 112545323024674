import api from "./axiosInstance";

export const enrollAttendeeApi = (data: {
  user:
    | number
    | {
        email: string;
        firstName?: string;
        lastName?: string;
        phone?: string;
      };
  attendee:
    | number
    | {
        firstName: string;
        lastName: string;
        birthdate: Date;
        sex: "male" | "female" | "other";
        phone: string;
        email: string;
      };
  scheduleIds: number[];
}) => api.post("/instructor/enroll", data);

export const fetchUsersApi = (search?: string) =>
  api.get("/instructor/users", { params: { search } });

export const fetchAttendeesApi = (userId: number, search?: string) =>
  api.get(`/instructor/attendees/${userId}`, { params: { search } });

export const fetchSchedulesApi = (classId: number) =>
  api.get(`/instructor/schedules/${classId}`);
