import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AttendanceReports from "./AttendanceReports/AttendanceReports";
import InstructorHoursReports from "./InstructorHoursReports/InstructorHoursReports";
import EnrollmentsReports from "./EnrollmentsReports/EnrollmentsReports";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`reports-tabpanel-${index}`}
      aria-labelledby={`reports-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `reports-tab-${index}`,
    "aria-controls": `reports-tabpanel-${index}`,
  };
}

export default function ReportsTabContainer() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="reports tabs">
          <Tab
            label={
              <FormattedMessage
                id="admin.dashboard.reports.attendanceReports"
                defaultMessage="Attendance Reports"
              />
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <FormattedMessage
                id="admin.dashboard.reports.instructorHours"
                defaultMessage="Instructor Hours"
              />
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <FormattedMessage
                id="admin.dashboard.reports.enrollments"
                defaultMessage="Enrollments"
              />
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AttendanceReports />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InstructorHoursReports />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EnrollmentsReports />
      </TabPanel>
    </Box>
  );
}
