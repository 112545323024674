import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { setFrom } from "../redux/auth/authSlice";

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, from } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    if (location.state?.from) {
      dispatch(setFrom(location.state.from));
    }
  }, [location.state?.from, dispatch]);

  useEffect(() => {
    if (isAuthenticated && from && from.includes("/enroll")) {
      navigate(from);
    }
  }, [isAuthenticated, from, navigate]);

  return <>{children}</>;
};

export default AuthWrapper;
